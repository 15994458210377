import {
  Box,
  Dialog,
  DialogContent,
} from "@mui/material";
import closeIcon from "../../../assets/close-icon.png"
import styled from "styled-components";
import { useRef, useState } from "react";
import { API_URL_UPLOAD_REPORTS, DEVICE } from "../../../utilities/constants";
import axios from "axios";
import { useCookies } from "react-cookie";
import TextEditor from "../../../pages/textEditor";
import html2pdf from "html2pdf.js";
import AttachStudyReport from "../AddRecordsModal/AttachStudyReport";
import AudioRecordModal from "../audioScribe";


const Button = styled.button`
  background-color: #7aba56;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  min-width: 200px;
  height: 40px;
  border: none;
  cursor: pointer;
  padding: 0 10px;

  &:disabled {
    background-color: #ddebed;
    color: #fff;
    cursor: not-allowed;
  }
`;
const ReportShowContainer = styled.div`
  background-color: #fff;
  padding: 20px;
`;

const ReviewButtonContainer = styled.div`
  // margin-left: 10px;
  display: flex;
  gap: 4px;
  @media ${DEVICE.laptop} {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 0px;
  }
`;
const AddTempWrapper = styled.div`
  // margin-left: 10px;
  @media ${DEVICE.laptop} {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 0px;
  }
`;

const SkipReview = styled.div`
  font-size: 20px;
  text-decoration: underline;
  color: #808080;
  cursor: pointer;
  display: flex;
  margin-left: auto;
  align-items: center;
`;
const ButtonBase = styled.button<{ active: boolean }>`
  border-radius: ${({ active }) =>
    active ? "8px 8px 0px 0px" : "8px 8px 8px 8px"};
  font-size: 16px;
  width: 145px;
  height: 46px;
  // border: ${({ active }) => (active ? "none" : "1px solid #7ABA56")};
  // background-color: ${({ active }) => (active ? "#7ABA56" : "#FFFFFF")};
  // color: ${({ active }) => (active ? "#FFFFFF" : "#7ABA56")};

  border: ${({ active }) => (active ? "none" : "1px solid #fff")};
  background-color: ${({ active }) => (active ? "#FFFFFF" : "#d7eff7")};
  color: ${({ active }) => (active ? "#30aed7" : "#000")};
  cursor: pointer;
`;

export interface EditTestProps {
  open: boolean;
  testId: string | null;
  title?: string | null | undefined;
  discription?: string | null
  closeHandler: () => void;
  setMediaArray: any;
  scansData: any;
  mediaArrayData: any
  attchReports: any,
  isAttachLoading: boolean
}

const ReportAttachDialog = ({
  open,
  closeHandler,
  setMediaArray,
  scansData,
  mediaArrayData,
  attchReports,
  isAttachLoading
}: EditTestProps) => {

  const [cookies] = useCookies();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoadingReport, setIsLoading] = useState<boolean>(false);
  const [mediaObject, setMediaObject] = useState<any>({});
  const [previewReport, setPreviewReport] = useState<any>("");
  const [activeButton, setActiveButton] = useState<any>("typeReport");
  const templateDownloadRef: any = useRef<HTMLDivElement>();
  const [templateData, setTemplateData] = useState<string>("");
  const [isTemplate, setTemplate] = useState<boolean>(false);
  const [sharingStatus, setSharingStatus] = useState<any>("AVAILABLE");
  const [isTranscriptionProgress, setIsTranscriptionProgress] =
    useState("start");

  const optionsSummary = {
    margin: [5, 0],
    filename: "summary.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2, logging: true, dpi: 192, letterRendering: true },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  };

  const saveTemplate = (content: string, status?: string) => {

    setIsLoading(true);
    setTemplateData(content);
    setSharingStatus(status)
    setTemplate(true);

    setTimeout(() => {
      onDownload(status);
    }, 2000);
  };

  const onDownload = async (status?: string) => {
    setIsLoading(true);

    templateDownloadRef.current.style.display = "block";
    const summaryBlob = await html2pdf()
      .from(templateDownloadRef.current)
      .set(optionsSummary)
      .outputPdf("blob");
    const summaryFile = new File([summaryBlob], `typereport${new Date().toISOString()}.pdf`, {
      type: "application/pdf",
    });
    uploadFile(summaryFile, status);
    templateDownloadRef.current.style.display = "none";
    setTemplate(false);
    // const blobUrl = URL.createObjectURL(summaryFile);
    // window.open(blobUrl, "_blank");
  };



  const handleSave = (data: any) => {
    console.log('dataa', data, scansData)

    if (scansData?.is_sent) {
      console.log("fgsgfu6rtwuyr6wuytdasuftas");

      attchReports(data.mediaArray)
    } else {
      setMediaArray([...data.mediaArray, ...mediaArrayData]);
      closeHandler()

    }

  };
  const handleSaveTemplate = (data: any, status?: string) => {

    const updatedItem = {
      ...data[0],
      status: status ? status : "AVAILABLE",
    };

    if (scansData?.is_sent) {
      attchReports([updatedItem]).then(() => {
        setIsLoading(false);
        closeHandler()

      })
    } else {
      const updatedItem = {
        ...data[0],
        status: status ? status : "AVAILABLE",
      };
      setMediaArray([updatedItem, ...mediaArrayData]);
    }
  };

  const uploadFile = (file: File, status?: string) => {
    setIsLoading(true);
    const token = cookies["access_token"];
    var formData = new FormData();
    formData.append("files", file);
    formData.append("file_type", "report");
    axios
      .post(API_URL_UPLOAD_REPORTS, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: any) => {
        if (response && response.data) {

          setMediaObject(response?.data?.result);
          setPreviewReport(file);
          handleSaveTemplate(response?.data?.result, status)
        } else {
          alert("Something went wrong!");
          closeHandler()
        }
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.log(error);
        closeHandler()
      });
  };
  const [audioScribeText, setAudioScribeText] = useState<any>("");



  return (
    <Dialog
      fullScreen
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ padding: "60px", }}
    >

      <Box sx={{ backgroundColor: '#F5FBFC', height: "100%" }}>
        <DialogContent
          style={{ justifyContent: "flex-start" }}
        >
          <div>
            <ReviewButtonContainer>
              <AddTempWrapper>
                <ButtonBase
                  active={activeButton === "dragupload"}
                  onClick={() => {
                    setActiveButton("dragupload");
                  }}
                >
                  Drag & Upload
                </ButtonBase>
              </AddTempWrapper>
              <AddTempWrapper>
                <ButtonBase
                  active={activeButton === "typeReport"}
                  onClick={() => {
                    setActiveButton("typeReport");
                  }}
                >
                  Type Report
                </ButtonBase>
              </AddTempWrapper>
              <AddTempWrapper>
                <ButtonBase
                  active={activeButton === "audio"}
                  onClick={() => {
                    setAudioScribeText('')
                    setActiveButton("audio");
                  }}
                >
                  Audio
                </ButtonBase>
              </AddTempWrapper>
              <SkipReview onClick={closeHandler}>
                <img onClick={closeHandler} height={50} width={50} style={{ cursor: "pointer", position: "absolute", right: " 0", top: "2px" }} src={closeIcon} />
              </SkipReview>

            </ReviewButtonContainer>

            <ReportShowContainer>
              {activeButton === "dragupload" ? (
                <>
                  <AttachStudyReport
                    open={true}
                    isAddRecordLoading={isAttachLoading}
                    handleSave={handleSave} />
                </>
              ) : activeButton === "audio" ? (<>

                <AudioRecordModal
                  open={open}
                  handleClose={closeHandler}
                  handleSave={(data: any) => setAudioScribeText(data)}
                  setIsTranscriptionProgress={setIsTranscriptionProgress}
                  isTranscriptionProgress={isTranscriptionProgress}
                  postTranscriptCallback={(data: any) => console.log("data", data)}
                />

                {audioScribeText &&
                  <>
                    <div
                      style={{
                        width: "80%",
                        alignSelf: "center",
                        margin: "0 auto",
                      }}
                    >
                      <TextEditor
                        isReviewModal={true}
                        isLoadingData={isLoadingReport}
                        scanData={scansData}
                        setContent={saveTemplate}
                        isShowSharing={true}
                        isAudioScribe={true}
                        audioScribeText={audioScribeText}
                      />
                    </div>
                    {isTemplate ? (
                      <div style={{ marginTop: "400px" }}>
                        <div
                          style={{
                            padding: "5px",
                            transform: "scale(0.98)",
                          }}
                          ref={templateDownloadRef}
                          dangerouslySetInnerHTML={{
                            __html: templateData,
                          }}
                        ></div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>}

              </>) : (
                <>
                  <div
                    style={{
                      width: "80%",
                      alignSelf: "center",
                      margin: "0 auto",
                    }}
                  >
                    <TextEditor
                      isReviewModal={true}
                      isLoadingData={isLoadingReport}
                      scanData={scansData}
                      setContent={saveTemplate}
                      isShowSharing={true}
                      isAudioScribe={false}
                    />
                  </div>
                  {isTemplate ? (
                    <div style={{ marginTop: "400px" }}>
                      <div
                        style={{
                          padding: "5px",
                          transform: "scale(0.98)",
                        }}
                        ref={templateDownloadRef}
                        dangerouslySetInnerHTML={{
                          __html: templateData,
                        }}
                      ></div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </ReportShowContainer>
          </div>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ReportAttachDialog;
