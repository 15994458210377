import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import axios from "axios";
import styled from "styled-components";
import {
    API_URL, FONT_FAMILY_GENERAL, API_KEY, COOKIE_EXPIRY_DAYS
  } from "../utilities/constants";
  import IBackArrow from "../assets/arrow-right-black.svg";

const Container = styled.div({
  backgroundColor: "#0099CC",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: FONT_FAMILY_GENERAL,
});

const TitleText = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #fff;
`;

const SubTitleText = styled.div`
  font-size: 14px;
  color: #fff;
  margin-top: 100px;
`;

const CountryPicker = styled.div`
  background-color: #fff;
  width: 300px;
  height: 120px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 10px;
`;

const CountryListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 14px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  &:last-child {
    border-bottom: none;
  }
`;
const LeftSection = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;
const RightSection = styled.div`
  display: flex;
  flex: 0.65;
  justify-content: flex-start;
  align-items: center;
`;
const CountryImage = styled.img`
  width: 25px;
  height: 25px;
`;
const CountryName = styled.div`
  font-size: 18px;
  color: #000;
  margin-left: 20px;
`;

export const BackArrowImg = styled.img`
  width: 32px;
  height: 32px;
`;


const ComplianceRoot = ({handleContrySlection,supportedCountriesData}) => {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['countryData']);
    const [supportedCountries, setSupportedCountries] = useState([]);

  useEffect(() => {
    if(supportedCountriesData?.length){
      setSupportedCountries(supportedCountriesData)
    }
  }, [supportedCountriesData]);

  const onCountryListItemClick = async (data) => {
    const serializedData = JSON.stringify(data);
    const maxAge = 60 * 60 * 24 * COOKIE_EXPIRY_DAYS;
    setCookie('countryData', serializedData, { path: '/',maxAge: maxAge, });
    handleContrySlection(data)
    // navigate("/login");
  };

  const fetchCountries = () => {
    const url = `${API_URL}/countries`;
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-KEY": API_KEY,
        }
    };
    axios
    .get(url, config)
    .then((response) => {
      if (
        response &&
        response.data
      ) {
        setSupportedCountries(response?.data?.data);
      } else {
        alert("Something went wrong!")
      }
    })
    .catch((error) => {
      console.log(error);
    })
  };

  const getCountryList = () => {
    if (supportedCountries?.length <= 0) return;
    return supportedCountries?.map((item, index) => {
      return (
        <CountryListItem
          key={`country_list_${index}`}
          onClick={() => onCountryListItemClick(item)}
        >
          <LeftSection>
            <CountryImage src={item?.icon} alt={item?.display_name} />
            <CountryName>{item?.display_name}</CountryName>

          </LeftSection>
          {/* <RightSection>
          </RightSection> */}
          <BackArrowImg style={{height:'20px',width:'20px'}} src={IBackArrow} />
        </CountryListItem>
      );
    });
  };

  return (
    <>
      <CountryPicker>{getCountryList()}</CountryPicker>
    </>
  );
};

export default ComplianceRoot;
