import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./Terms.css";
import logo from "../assets/logo-white.svg";
import { DEVICE, HEADER_HEIGHT } from "../utilities/constants";
import { Typography, Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import moment from "moment";

const StickyHeader = styled.div`
  background-color: #fff;
  color: #333;
  z-index: 1;
  height: ${HEADER_HEIGHT}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 52px;
  border-bottom: 1px solid rgba(36, 119, 179, 0.1);
  @media ${DEVICE.mobileL} {
    padding: 0px 15px;
    flex-direction: column-reverse;
  }
`;

const HeaderText = styled.h1`
  font-size: 20px;
  @media ${DEVICE.mobileL} {
    font-size: 12px;
  }
`;

const HeaderLogo = styled.img`
  @media ${DEVICE.mobileL} {
    margin-top: 20px;
  }
`;

const TCText = styled.div({
  textAlign: "left",
  // backgroundColor: "red",
  fontSize: "13px",
  color: "#808080",
});

const Terms = () => {
  const docs = [
    {
      uri: "https://drive.google.com/file/d/10H5fO11_Y4uWRsnLpx1Eg0C_kEqrshvY/view",
    },
  ];
  return (
    <>
      {/* <DocViewer documents={docs} /> */}
      <StickyHeader>
        <HeaderText>TERMS AND CONDITIONS</HeaderText>
        <HeaderLogo src={logo} />
      </StickyHeader>

      <Box
        id=""
        className=""
        sx={{ m: "0 auto 10px", textAlign: "left", maxWidth: "800px", p: "20px" }}
      >
        <Typography
          variant="h4"
          sx={{ textAlign: "center", textDecoration: "underline" }}
        >
          Data Processing Agreement
        </Typography>
        <Typography
          variant="body2"
          sx={{ textAlign: "center", mt: 2, fontSize: "18px" }}
        >
          This Data Processing Agreement (“<b>Agreement</b>”) forms part of the
          Contract for Services <br />
          (“<b>Principal Agreement</b>”) between:{" "}
        </Typography>
        <br />
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          (the "<b>Company</b>")
        </Typography>
        <br />
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          and
        </Typography>
        <br />
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          <b>Heme Health</b>
        </Typography>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          (the “<b>Data Processor</b>”)
        </Typography>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          (together as the “<b>Parties</b>”)
        </Typography>

        <Box sx={{ my: 4}} >
          <Typography variant="h6">
            <b>WHEREAS</b>
          </Typography>
          <Typography variant="body2">
            (A) The Company acts as a Data Fiduciary.{" "}
          </Typography>
          <Typography variant="body2">
            (B) The Company wishes to subcontract certain Services, which imply
            the processing of personal data, to the Data Processor.
          </Typography>
          <Typography variant="body2">
            (C) The Parties seek to implement a data processing agreement that
            complies with the requirements of the current legal framework in
            relation to data processing and with India’s Digital Personal Data
            Protection Act of 2023 (DPDP 2023 or “The Act”).
          </Typography>
          <Typography variant="body2">
            (D) The Parties wish to lay down their rights and obligations.
          </Typography>
        </Box>
        <Typography variant="body2">It is agreed as follows:</Typography>

        <Box sx={{pb:4}} className="term-and-condition">
          <ol style={{ padding: "0" }}>
            <li>
              <b>1. DEFINITIONS</b>
              <Box>
                <Typography variant="body2">
                  <b>
                    Unless otherwise defined herein, capitalised terms and
                    expressions used in this Agreement shall have the fullowing
                    meaning, as per the DPDP 2023:
                  </b>
                </Typography>
                <Typography variant="body2">
                  <b>“Appellate Tribunal” </b>means the Telecom Disputes
                  Settlement and Appellate Tribunal established under section 14
                  of the Telecom Regulatory Authority of India Act, 1997;
                </Typography>
                <Typography variant="body2">
                  <b>“Automated”</b> means any digital process capable of
                  operating automatically in response to instructions given or
                  otherwise for the purpose of processing data;
                </Typography>
                <Typography variant="body2">
                  <b>“Board” </b>means the Data Protection Board of India
                  established by the Central Government under section 18;
                </Typography>
                <Typography variant="body2">
                  <b>“Certain legitimate uses”</b> means the uses referred to in
                  section 7;{" "}
                </Typography>
                <Typography variant="body2">
                  <b>“Chairperson”</b> means the Chairperson of the Board;
                </Typography>
                <Typography variant="body2">
                  <b>“Child”</b> means an individual who has not completed the
                  age of eighteen years;
                </Typography>
                <Typography variant="body2">
                  <b>“Consent Manager”</b> means a person registered with the
                  Board, who acts as a single point of contact to enable a Data
                  Principal to give, manage, review and withdraw her consent
                  through an accessible, transparent and interoperable platform;
                </Typography>
                <Typography variant="body2">
                  <b>“Data”</b> means a representation of information, facts,
                  concepts, opinions or instructions in a manner suitable for
                  communication, interpretation or processing by human beings or
                  by automated means;
                </Typography>
                <Typography variant="body2">
                  <b>“Data Fiduciary”</b> means any person who alone or in
                  conjunction with other persons determines the purpose and
                  means of processing of personal data;
                </Typography>
                <Typography variant="body2">
                  <b>“Data Principal”</b> means the individual to whom the
                  personal data relates and where such individual is— (i) a
                  child, includes the parents or lawful guardian of such a
                  child; (ii) a person with disability, includes her lawful
                  guardian, acting on her behalf;
                </Typography>
                <Typography variant="body2">
                  <b>“Data Processor” </b>means any person who processes
                  personal data on behalf of a Data Fiduciary;
                </Typography>
                <Typography variant="body2">
                  <b>“Data Protection Officer”</b> means an individual appointed
                  by the Significant Data Fiduciary under clause (a) of
                  sub-section (2) of section 10;
                </Typography>
                <Typography variant="body2">
                  <b>“Digital office”</b> means an office that adopts an online
                  mechanism wherein the proceedings, from receipt of intimation
                  or complaint or reference or directions or appeal, as the case
                  may be, to the disposal thereof, are conducted in online or
                  digital mode;
                </Typography>
                <Typography variant="body2">
                  <b>“Digital personal data” </b>means personal data in digital
                  form;{" "}
                </Typography>
                <Typography variant="body2">
                  <b>“Gain”</b> means— (i) a gain in property or supply of
                  services, whether temporary or permanent; or (ii) an
                  opportunity to earn remuneration or greater remuneration or to
                  gain a financial advantage otherwise than by way of legitimate
                  remuneration;
                </Typography>
                <Typography variant="body2">
                  <b>“Loss”</b> means— (i) a loss in property or interruption in
                  supply of services, whether temporary or permanent; or (ii) a
                  loss of opportunity to earn remuneration or greater
                  remuneration or to gain a financial advantage otherwise than
                  by way of legitimate remuneration; Definitions. 24 of
                  1997.SEC. 1] THE GAZETTE OF INDIA EXTRAORDINARY 3
                </Typography>
                <Typography variant="body2">
                  <b>“Member”</b> means a Member of the Board and includes the
                  Chairperson;{" "}
                </Typography>
                <Typography variant="body2">
                  <b>“Notification”</b> means a notification published in the
                  Official Gazette and the expressions “notify” and “notified”
                  shall be construed accordingly;
                </Typography>
                <Typography variant="body2">
                  <b>“Person”</b> includes— (i) an individual; (ii) a Hindu
                  undivided family; (iii) a company; (iv) a firm; (v) an
                  association of persons or a body of individuals, whether
                  incorporated or not; (vi) the State; and (vii) every
                  artificial juristic person, not falling within any of the
                  preceding sub-clauses;
                </Typography>
                <Typography variant="body2">
                  <b>“Personal data”</b> means any data about an individual who
                  is identifiable by or in relation to such data;
                </Typography>
                <Typography variant="body2">
                  <b>“Personal data breach”</b> means any unauthorised
                  processing of personal data or accidental disclosure,
                  acquisition, sharing, use, alteration, destruction or loss of
                  access to personal data, that compromises the confidentiality,
                  integrity or availability of personal data;
                </Typography>
                <Typography variant="body2">
                  <b>“Prescribed”</b> means prescribed by rules made under this
                  Act;
                </Typography>
                <Typography variant="body2">
                  <b>“Proceeding”</b> means any action taken by the Board under
                  the provisions of this Act;
                </Typography>
                <Typography variant="body2">
                  <b>“Processing”</b> in relation to personal data, means a
                  wholly or partly automated operation or set of operations
                  performed on digital personal data, and includes operations
                  such as collection, recording, organisation, structuring,
                  storage, adaptation, retrieval, use, alignment or combination,
                  indexing, sharing, disclosure by transmission, dissemination
                  or otherwise making available, restriction, erasure or
                  destruction;
                </Typography>
                <Typography variant="body2">
                  <b>“She” </b>in relation to an individual includes the
                  reference to such individual irrespective of gender;
                </Typography>
                <Typography variant="body2">
                  <b>“Significant Data Fiduciary”</b> means any Data Fiduciary
                  or class of Data Fiduciaries as may be notified by the Central
                  Government under section 10;
                </Typography>
                <Typography variant="body2">
                  <b>“Specified purpose” </b>means the purpose mentioned in the
                  notice given by the Data Fiduciary to the Data Principal in
                  accordance with the provisions of this Act and the rules made
                  thereunder; and
                </Typography>
                <Typography variant="body2">
                  <b>“State”</b> means the State as defined under article 12 of
                  the Constitution
                </Typography>
              </Box>
            </li>

            <li style={{ marginTop: "20px" }}>
              <b>2. PROCESSING OF COMPANY PERSONAL DATA 88</b>

              <Typography variant="body2">Processor shall: </Typography>
              <Box>
                <ul>
                  <li>
                    {" "}
                    <Typography variant="body2">
                      comply with all applicable Data Protection Laws in the
                      Processing of Company Personal Data; and{" "}
                    </Typography>
                  </li>
                  <li>
                    {" "}
                    <Typography variant="body2">
                      not Process Company Personal Data other than on the
                      relevant Company’s documented instructions.
                    </Typography>
                  </li>
                  <li>
                    {" "}
                    <Typography variant="body2">
                      The Company instructs Processor to process Company
                      Personal Data
                    </Typography>
                  </li>
                </ul>
              </Box>
            </li>

            <li style={{ marginTop: "20px" }}>
              <b>3. PROCESSOR PERSONNEL</b>
              <Typography variant="body2">
                {" "}
                Processor shall take reasonable steps to ensure the reliability
                of any employee, agent or contractor of any Contracted Processor
                who may have access to the Company Personal Data, ensuring in
                each case that access is strictly limited to those individuals
                who need to know / access the relevant Company Personal Data, as
                strictly necessary for the purposes of the Principal Agreement,
                and to comply with Applicable Laws in the context of that
                individual's duties to the Contracted Processor, ensuring that
                all such individuals are subject to confidentiality undertakings
                or professional or statutory obligations of confidentiality.{" "}
              </Typography>
            </li>
            <li style={{ marginTop: "20px" }}>
              <b>4. SECURITY</b>
              <Typography variant="body2">
                {" "}
                Taking into account the state of the art, the costs of
                implementation and the nature, scope, context and purposes of
                Processing as well as the risk of varying likelihood and
                severity for the rights and freedoms of natural persons,
                Processor shall in relation to the Company Personal Data
                implement appropriate technical and organisational measures to
                ensure a level of security appropriate to that risk, including,
                as appropriate, the measures referred to in the DPDP. In
                assessing the appropriate level of security, the Processor shall
                take account in particular of the risks that are presented by
                Processing, in particular from a Personal Data Breach.
              </Typography>
            </li>
            <li style={{ marginTop: "20px" }}>
              <b>5. SUBPROCESSING</b>
              <Typography variant="body2">
                Processor shall not appoint (or disclose any Company Personal
                Data to) any Subprocessor unless required or authorised by the
                Company.
              </Typography>
            </li>
            <li style={{ marginTop: "20px" }}>
              <b>6. DATA PRINCIPAL RIGHTS</b>
              <Typography variant="body2">
                Taking into account the nature of the Processing, Processor
                shall assist the Company by implementing appropriate technical
                and organisational measures, insofar as this is possible, for
                the fulfilment of the Company obligations, as reasonably
                understood by Company, to respond to requests to exercise Data
                Principal rights under the Data Protection Laws.
              </Typography>
              <Typography variant="body2">Processor shall:</Typography>
              <ul>
                <li>
                  promptly notify Company if it receives a request from a Data
                  Principal under any Data Protection Law in respect of Company
                  Personal Data;
                </li>
                <li>
                  ensure that it does not respond to that request except on the
                  documented instructions of Company or as required by
                  Applicable Laws to which the Processor is subject, in which
                  case Processor shall to the extent permitted by Applicable
                  Laws inform Company of that legal requirement before the
                  Contracted Processor responds to the request.
                </li>
              </ul>
            </li>
            <li style={{ marginTop: "20px" }}>
              <b>7. PERSONAL DATA BREACH</b>
              <Typography variant="body2">
                Processor shall notify Company without undue delay upon
                Processor becoming aware of a Personal Data Breach affecting
                Company Personal Data, providing Company with sufficient
                information to allow the Company to meet any obligations to
                report or inform Data Subjects of the Personal Data Breach under
                the Data Protection Laws. Processor shall cooperate with the
                Company and take reasonable commercial steps as directed by
                Company to assist in the investigation, mitigation and
                remediation of each such Personal Data Breach.{" "}
              </Typography>
            </li>
            <li style={{ marginTop: "20px" }}>
              <b>8. DELETION OR RETURN OF COMPANY PERSONAL DATA</b>
              <Typography variant="body2">
                Processor shall promptly comply with Data Fiduciary request to
                erase any personal data that was made available for processing
                by the Data Fiduciary to the Data Processor, unless retention is
                necessary for compliance with any law for the time being in
                force, as per the DPDP.
              </Typography>
            </li>
            <li style={{ marginTop: "20px" }}>
              <b>9. AUDIT RIGHTS</b>
              <Typography variant="body2">
                Processor shall make available to the Company on request all
                information necessary to demonstrate compliance with this
                Agreement, and shall allow for and contribute to audits,
                including inspections, by the Company or an auditor mandated by
                the Company in relation to the Processing of the Company
                Personal Data by the Contracted Processors. Information and
                audit rights of the Company only arise to the extent that the
                Agreement does not otherwise give them information and audit
                rights meeting the relevant requirements of Data Protection Law.
              </Typography>
            </li>
            <li style={{ marginTop: "20px" }}>
              <b>10. DATA TRANSFER </b>
              <Typography variant="body2">
                The Processor may not transfer or authorise the transfer of Data
                to countries outside India without the prior written consent of
                the Company. If personal data processed under this Agreement is
                transferred outside India, the Parties shall ensure that the
                personal data are adequately protected. To achieve this, the
                Parties shall, unless agreed otherwise, rely on standard
                contractual clauses as per DPDP for the transfer of personal
                data.
              </Typography>
            </li>
            <li style={{ marginTop: "20px" }}>
              <b>11. GENERAL TERMS</b>
              <b> Confidentiality. </b>
              <Typography variant="body2">
                Each Party must keep this Agreement and information it receives
                about the other Party and its business in connection with this
                Agreement (“Confidential Information”) confidential and must not
                use or disclose that Confidential Information without the prior
                written consent of the other Party except to the extent that:
                (a) disclosure is required by law; (b) the relevant information
                is already in the public domain.{" "}
              </Typography>
              <Box sx={{ mt: 3 }}>
                <b> Notices. </b>
                <Typography variant="body2">
                  All notices and communications given under this Agreement must
                  be in writing and will be delivered personally, sent by post
                  or sent by email to the address or email address set out in
                  the heading of this Agreement at such other address as
                  notified from time to time by the Parties changing address.
                </Typography>
              </Box>
            </li>
            <li style={{ marginTop: "20px" }}>
              <b>12. GOVERNING LAW AND JURISDICTION</b>
              <Typography variant="body2">
                This Agreement is governed by the laws of the Republic of
                India.. Any dispute arising in connection with this Agreement,
                which the Parties will not be able to resolve amicably, will be
                submitted to the exclusive jurisdiction of the courts of Mumbai.
              </Typography>
            </li>
          </ol>
          <Box>
            <Typography variant="body2">
              <b>IN WITNESS WHEREOF, </b>this Agreement is entered into with
              effect from the date first set out below.
            </Typography>
          </Box>
          <Box sx={{ mt: 5 }}>
            <Typography variant="h6">Company</Typography>
            <Typography variant="body2">
              Signature &nbsp; <span style={{fontSize:'14px',fontWeight:'bold'}}>Accepted</span>
            </Typography>
            <Typography variant="body2">
              Name: &nbsp; _________________
            </Typography>
            <Typography variant="body2">
              Title: &nbsp; _________________
            </Typography>
            <Typography variant="body2">
              Date Signed: &nbsp; {moment().format("DD-MM-YYYY")}
            </Typography>
          </Box>
          
        </Box>

        <Divider />
        <Box sx={{ my: 5 }}>
          <Typography variant="h5" sx={{ textDecoration: "underline", pb: 4,textAlign:"center" }}>
            <b>Privacy Policy</b>
          </Typography>
          <Typography variant="h5" sx={{ pb: 2 }}>
            <b>Introduction:</b>
          </Typography>
          <Typography variant="body2">
            At Heme Health we believe as patients you should own your personal
            health data and manage your healthcare needs. We pledge to respect
            your privacy and have developed our Privacy Policy that explains how
            we collect, use, and disclose your personal information. Our privacy
            policy conforms to the Digital Personal Data Protection 2023 (DPDP).
          </Typography>
          <br></br>
          <Typography variant="body2">
            This Privacy Statement outlines the way in which Heme Health uses
            and processes the information relating to you, the user of the Heme
            Health Website, Portal and/or the App (hereinafter referred to as
            “User” or “You” or similar). By using the website, portal or app,
            You consent to our collection, use, and disclosure of Your personal
            information in accordance with our Privacy Policy.
          </Typography>
          <br></br>
          <Typography variant="body2">
            In addition to this privacy statement, Heme Health is also committed
            to the highest standards of data governance across India and the UK.
          </Typography>
          <br></br>
          <Typography variant="body2">
            Towards this, we have completed and are undergoing governmental and
            national regulatory compliances as follows -
          </Typography>
          <ul>
            <li> <Typography variant="body2">
              Heme Health is in the process of undergoing certification under
              the ABDM Programme of the Ministry of Health, Government of India.
              </Typography>
             </li>
            <li> <Typography variant="body2">
              Heme Health is certified by the UK National Health Service Digital
              Security Toolkit (NHS DSPT).
              </Typography>
              <ul>
                <li> <Typography variant="body2">Vide NHS DSPT number: C9Y3G 
              </Typography>
                </li>
              </ul>
             </li>
            <li> <Typography variant="body2">
              Heme Health is registered with the Information Commissioner's
              Office (ICO) which regulates data protection in the UK.
              </Typography>
              <ul>
                <li> <Typography variant="body2">Vide ICO registration number: ZB653392
              </Typography>
                 </li>
              </ul>
            </li>
          </ul>
          <Typography variant="body2"sx={{mb:2}}><b>Our contact details:</b></Typography>
          <Typography variant="body2">Name: Heme Health</Typography>
          <Typography variant="body2">Address: 103-104, Fulcrum Hiranandani Business Park, Airport Road, Andheri East,</Typography>
          <Typography variant="body2">Mumbai-400099, MH</Typography>
          <Typography variant="body2">E-mail: <a href="#">info@hemehealth.io</a></Typography>
          <Typography variant="body2" sx={{my:2}}><b>The type of personal information we collect:</b></Typography>
          <Typography variant="body2">Heme Health manages patient data in compliance with India's Digital Personal Data Protection Act of 2023 (DPDP 2023 or “The Act”) across the entire spectrum of the healthcare ecosystem including clinicians, doctors, pathology labs, and hospitals.</Typography>
          <Typography variant="body2">We currently collect and process the following information:</Typography>
          <ul>
            <li> <Typography variant="body2">Personal identifiers, contacts and characteristics (for example, name, contact details, date of birth and gender)</Typography></li>
            <li> <Typography variant="body2">Personal health information (information related to your health history; for example medical imaging files, diagnostic reports, prescriptions, medication lists, questionnaire responses, letters, photos, notes etc)</Typography></li>
          </ul>
          <Typography variant="body2" sx={{pb:2}}><b>How we get the personal information and why we have it</b></Typography>
          <Typography variant="body2">Heme Health is a Data Processor in the context of DPDP 2023 i.e. an organisation that processes data on behalf of Data Fiduciaries - also known as Data Controllers in GDPR terminology - like clinicians, pathology labs, or hospitals.</Typography>
          <Typography variant="body2">Data Fiduciaries - like clinicians, pathology labs, or hospitals - decide what patient data is collected and retain control over the same based on “consent for legitimate use” from patients or Data Principals - also known as Data Subjects in GDPR terminology.</Typography>
          <Typography variant="body2">Most of the personal information we process is provided to us directly by you for one of the following reasons:</Typography>
          <ul>
            <li> <Typography variant="body2">For you to store, access, query, view and share your health data with whomever you authorise</Typography></li>
          </ul>
          <Typography variant="body2">We also receive personal information indirectly, based on “consent for legitimate use” from the following sources in the following scenarios:</Typography>
          <ul>
            <li> <Typography variant="body2">A third party you have requested your health data to be transferred from to store in your Heme Health App, via a Subject Access Request (for example a hospital or an imaging centre) or an access request in our application.</Typography></li>
          </ul>
          <Typography variant="body2">We use the information that you have given us in order to:</Typography>
          
          <ul> 
            <li> <Typography variant="body2">Store your personal health data securely in the Heme Health App</Typography></li>
          </ul>
          <Typography variant="body2">We may share this information with third parties with your consent or where otherwise permitted or required by applicable law or regulatory requirement as per the DPDP 2023.</Typography>
          <Typography variant="body2"><b>How we store your personal information</b></Typography>
          <Typography variant="body2">Your information is securely stored in India.
Heme Health retention periods for personal data are based on business needs and legal requirements. We will store your information for as long as necessary to fulfil the purposes indicated in this Policy or as otherwise permitted or required by law.</Typography>
          <Typography variant="body2"><b>Your data protection rights:</b></Typography>
          <Typography variant="body2">Under data protection law, you have rights including:</Typography>
          <ol>
            <li><Typography variant="body2"><b>Your right of access </b> - You have the right to ask us for copies of your personal information.</Typography></li>
            <li><Typography variant="body2"><b>Your right to correction </b> - You have the right to ask us to correct personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</Typography></li>
            <li><Typography variant="body2"><b>Your right to erasure </b> - You have the right to ask us to erase your personal information in certain circumstances.</Typography></li>
            <li><Typography variant="body2"><b>Your right to Grievance Redressal </b> - You have the right to register a complaint with a data fiduciary for any processing activity related to your personal data. If you are not satisfied with the response, you may register a complaint with the DPBI.</Typography></li>
            <li><Typography variant="body2"><b>Your right to Nominate </b> - You may nominate another individual who, in the event of death or incapacity of yourself, shall exercise your rights.</Typography></li>
          </ol>
          
          <Typography variant="body2">You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you.</Typography>
          <Typography variant="body2">Please contact us at <a href="#">info@hemehealth.io</a>, 103-104, Fulcrum Hiranandani Business Park, Airport Road, Andheri East, Mumbai-400099, MH if you wish to make a request.</Typography>
          <br/>
          <Typography variant="body2"><b>Changes to privacy policy</b></Typography>
          <Typography variant="body2">We reserve the right to change this Privacy Policy from time to time. If changes are significant, we’ll provide a more prominent notice (including, for certain services, email notification of Privacy Policy changes and request for your acceptance as needed).</Typography>
          <br/>
          <Typography variant="body2"><b>How to complain</b></Typography>
          <Typography variant="body2">If you have any concerns about our use of your personal information, you can make a complaint to us at <a href="#">info@hemehealth.io.</a> </Typography>
          <Typography variant="body2">Data Protection Officer: Ramiz Nathani</Typography>
          <Typography variant="body2">Last Updated: 23/02/24</Typography>
        </Box>
        {/* <ol>
          <li>
            BACKGROUND:
            <ol>
              <li>
                Heme Health Private Limited (“Company”) has developed the Heme
                App which shall assist individuals to store and access their
                Medical Records in a centralized application (defined below)
                (“App”).
              </li>
              <li>
                You is a registered a pathology testing center with requisite
                accreditations (Lab/You").
              </li>
              <li>
                This application is for use by pathology testing center only to
                share medical records with individuals.
              </li>
            </ol>
          </li>
          <li>
            INTRODUCTION:
            <ol>
              <li>
                These Terms and Conditions (“T&Cs”) shall constitute an
                electronic Medical Record within the meaning of the Information
                Technology Act, 2000 and the rules made thereunder and under all
                other applicable laws as amended from time to time.
              </li>
            </ol>
          </li>
          <li>
            DEFINITIONS:
            <ol>
              <li>
                “Account” is the account successfully opened by the Lab on the
                App based on information as required to be entered during the
                Registration Process (defined below) and includes any further
                changes to the information made by the Lab from time to time.
              </li>
              <li>
                “Medical Records” is the electronic medical report generated by
                the Lab and then uploaded on the App.
              </li>
              <li>
                “Service” is collectively the various services offered by the
                Company as indicated in Annexure I of this T&Cs and other
                services may be introduced from time to time on the App.
              </li>
            </ol>
          </li>
          <li>
            ELIGIBILITY:
            <ol>
              <li>
                You covenant and undertake that you have availed all the
                relevant consents and authorisations under applicable law to
                operate as pathology testing center.
              </li>
              <li>
                You further covenant and undertake that the generation of the
                medical reports or any other associated document is in
                accordance with applicable law including but not limited to
                Electronic Health Medical Record Standards for India, 2016.
              </li>
              <li>
                You represent and warrant that you have the requisite right,
                authority, and capacity to enter into these T&Cs and to abide by
                all of the terms and conditions set forth herein.
              </li>
            </ol>
          </li>
          <li>
            SERVICES:
            <ol>
              <li>
                The Company shall provide access of the Web platform to the Lab
                with a view to optimize the process of delivery of Medical
                Records to its patients. Details of the services to be provided
                on the App have been set hereinbelow:
                <ol>
                  <li>Store patient contact information (phone number)</li>
                  <li>Store doctors contact information (phone numer)</li>
                  <li> Send medical record to the selected patient</li>
                  <li>Send medical record to the selected doctor</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            REGISTRATION PROCESS:
            <ol>
              <li>
                The Lab must complete the Registration Process as provided
                below:
                <ol>
                  <li>
                    The Lab must register using a phone number – and OTP will be
                    generated to access the lab portal.
                  </li>
                  <li>
                    Registration is a pre-requisite for the Lab to create an
                    Account and entails the provision of inter alia the
                    following basic details such as full name, email address,
                    city, zip/postal code and phone number.{" "}
                  </li>
                  <li>
                    The Registration Process is complete only after the Lab
                    agrees to be bound by the T&Cs.
                  </li>
                  <li>
                    The Lab further understands and agrees that the Company may
                    screen and verify the information provided by the Lab and at
                    its sole discretion, and modify the information requested
                    during the Registration Process and may ask for further
                    information even after Registration Process. The Company may
                    in its sole discretion, close the Account, if any
                    information provided is found to be false, incomplete or the
                    information provided is insufficient.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            CONDUCT BY THE LAB:
            <ol>
              <li>
                By indicating your acceptance of these T&Cs and selecting the “I
                Agree” button or by using the App, you hereby agree to the
                following:
                <ol>
                  <li>
                    You are aware of the contents of the MOU and agree to abide
                    by the same;
                  </li>
                  <li>
                    You have the right and authority to legally access the App
                    as an employee, officer or director (as applicable);
                  </li>
                  <li>
                    You have obtained all relevant consent and authorization
                    from the patient to share the Medical Records on the App;
                  </li>
                  <li>
                    You have ensure that the patient is aware of the services
                    being provided the App and that the Company /App is in no
                    way involved in generating the medical reports;
                  </li>
                  <li>
                    You are responsible for all the information provided by you
                    on the App including the Medical Records; and
                  </li>
                  <li>
                    You are consenting to be bound by these T&Cs privacy policy
                    and other agreements of use as may be indicated by the
                    Company from time to time and/or under applicable law.
                  </li>
                  <li>
                    By sharing the Medical Records with us, you explicitly agree
                    that we have the right to view the data (including sensitive
                    personal data or information) available in Medical Records.
                    All updates to the data (including sensitive personal data
                    or information) will also be visible to us but we shall not
                    be able to edit Medical Records.
                  </li>
                  <li>
                    We may process and display data (including sensitive
                    personal data or information) in the Medical Records to
                    support personalized medical report management for the
                    patients. We also process data for analysis without
                    disclosing individual identity medical analysis and research
                    as per our Privacy Policy.
                  </li>
                </ol>
              </li>
              <li>
                You agree that while utilizing the App, you shall not undertake
                the following :
                <ol>
                  <li>
                    reverse engineer, modify, copy, distribute, transmit,
                    display, perform, reproduce, publish, license, create
                    derivative works from, transfer, or sell any information or
                    software obtained from the App;
                  </li>
                  <li>
                    defame, abuse, harass, stalk, threaten or otherwise violate
                    the legal rights of others;
                  </li>
                  <li>
                    publish, post, upload, distribute or disseminate any
                    inappropriate, blasphemous, defamatory, infringing, obscene,
                    indecent or unlawful topic, name, material or information;
                  </li>
                  <li>
                    upload files that contain software or other material
                    protected by intellectual property laws unless you own or
                    control the rights thereof or have received all necessary
                    consents;
                  </li>
                  <li>
                    upload or distribute files that contain viruses, corrupted
                    files, or any other similar software or programs that may
                    damage the operation of the website or another's computer;
                  </li>
                  <li>
                    conduct or forward any marketing material, surveys,
                    contests, pyramid schemes or chain letters;
                  </li>
                  <li>
                    download any file posted by another user of a service that
                    you know, or reasonably should know, cannot be legally
                    distributed or used in such manner;
                  </li>
                  <li>
                    falsify or delete any author attributions, legal or other
                    proper notices or proprietary designations or labels of the
                    origin or source of software or other material contained in
                    a file that is uploaded;
                  </li>
                  <li>
                    violate any code of conduct or other guidelines, which may
                    be applicable for or to any particular service;
                  </li>
                  <li>
                    violate any applicable laws or regulations for the time
                    being in force in or outside India; and
                  </li>
                  <li>violate any of the T&Cs for the use of the App;</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            EFFECTIVE DATE:
            <ol>
              <li>
                This Agreement will come into effect from the date you login to
                the website.
              </li>
            </ol>
          </li>
          <li>
            ACCESS TO THE APP:
            <ol>
              <li>
                You accept a non-exclusive, personal, limited right to have
                access to the App in accordance with the MOU and the T&Cs.
                Unless otherwise explicitly mentioned in the MOU and the T&Cs,
                You have no right except the rights to use our App. Any
                unauthorized use of any of the materials or information on this
                App shall violate all the intellectual property rights owned by
                the Company in relation to the App.
              </li>
              <li>
                You also agree not to create user accounts by automated means or
                under false or fraudulent pretenses.
              </li>
            </ol>
          </li>
          <li>
            CREATING AND UPLOADING THE MEDICAL RECORDS:
            <ol>
              <li>
                You are solely responsible for generating a Medical Record based
                on the information and testing sample provided by the patient.
                The Company and the App shall in no manner whatsoever be
                responsible for the accuracy, veracity and/or correctness of the
                Medical Record generated by the Lab.
              </li>
              <li>
                You shall ensure that all relevant consents and authorisations
                under applicable law including but not limited to the Electronic
                Health Medical Record Standards for India, 2016 are obtained
                from the patient.
              </li>
              <li>
                You understand and agree that the Company may review any content
                and in case the Company finds, in its sole discretion that the
                Lab violates any terms of the T&Cs, the Company reserves the
                right to take actions to prevent/control such violation
                including without limitation, removing the Medical Record or any
                content from the App and/or blocking the Lab’s use of the App.
              </li>
              <li>
                Without prejudice, the Company shall be entitled to initiate any
                legal action/proceeding in accordance with applicable law.
              </li>
            </ol>
          </li>

          <li>
            INTELLECTUAL PROPERTY RIGHTS:
            <ol>
              <li>
                Unless otherwise indicated, the Company has the sole and
                exclusive right over all the intellectual property rights in
                relation to the App. You acknowledge that the App and any
                underlying technology or software used in connection with
                rendering the Services on the App are proprietary information
                owned by the Company, except where it is indicated otherwise.
              </li>
              <li>
                You shall not reproduce or distribute any content from the App
                without obtaining prior written authorization from the Company.
              </li>
              <li>
                If the Company believes in good faith any material on its App
                has been illegally copied or is posted, uploaded or made
                accessible through the App or Services, it may send an legal
                notice and remove access to the App for such offenders. Such
                legal notice sent by the Company is without prejudice to its
                rights to proceed against the offender by filing a lawsuit in
                the appropriate court of law on such grounds as the Company may
                deem fit.
              </li>
            </ol>
          </li>

          <li>
            CONFIDENTIAL INFORMATION:
            <ol>
              <li>
                Except as otherwise required by applicable law or judicial,
                administrative or governmental action, You may not, without our
                prior written consent, at any time, during or after the Term of
                the MOU, directly or indirectly, divulge or disclose
                Confidential Information for any purpose or use/Proprietary
                Confidential Information for its own benefit or for the purposes
                or benefit of any other person. This obligation remains in
                effect in perpetuity after the termination or expiration of this
                T&Cs.
              </li>
            </ol>
          </li>

          <li>
            DELIVERY:
            <ol>
              <li>
                The Lab shall upload the Medical Records within 1 hours, unless
                it is required otherwise and/or on account of a technical
                failure of the App. The Company shall not be liable for any
                delay in delivering the Medical Records on the App to the
                patient on account of delay by the Lab to upload the Medical
                Records on the App.
              </li>
            </ol>
          </li>

          <li>
            MAINTENACE:
            <ol>
              <li>
                The Company may at its sole discretion and without assigning any
                reason whatsoever at any time deactivate or/and suspend the
                Lab’s access to the App (as the case may be) without giving any
                prior notice, to carry out system maintenance or/and upgrading
                or/and testing or/and repairs or/and other related work
              </li>
              <li>
                Without prejudice to any other provisions of the T&Cs, the
                Company shall not be liable to indemnify the Lab for any loss or
                damage that the Lab may incur on account of such deactivation
                or/and suspension.
              </li>
            </ol>
          </li>

          <li>
            TERM AND TERMINATION:
            <ol>
              <li>
                Notwithstanding anything to the contrary in MOU/T&C, the Company
                has the right to immediately terminate the Terms without
                liability: (a) to comply with any order issued or proposed to be
                issued by any governmental agency; (b) to comply with any
                provision of law; or (c) if performance of any term of this
                MOU/T&C by either party would cause it to be in violation of
                lawor (iv) in case the Lab has breached any of the covenants
                and/ or undertakings set out in the Terms and Conditions.
              </li>
              <li>
                Upon termination of this arrangement, You will cease to use our
                App and the Company may terminate your access to it.
              </li>
            </ol>
          </li>

          <li>
            GOVERNING LAW AND DISPUTE RESOLUTION:
            <ol>
              <li>
                In the event of any dispute or difference arising under this
                agreement, the parties shall meet to arrive at an amicable
                resolution thereof. In the event, the parties fail to arrive at
                an amicable resolution within 30 days of the dispute having
                arisen, the matter shall be finally resolved by arbitration
                under a sole arbitrator appointed by the mutual consent of the
                Parties, failing which such sole arbitrator shall be appointed
                in terms the Arbitration and Conciliation Act 1996 (as amended
                from time to time). The seat and venue of the arbitration
                proceedings shall be Mumbai and the arbitration shall be
                conducted in the English language. Subject to the above, the
                courts at Mumbai shall have the sole and exclusive jurisdiction
                to resolve any disputes or disagreements that arise in relation
                to this agreement.
              </li>
            </ol>
          </li>

          <li>
            INDEMNITY:
            <ol>
              <li>
                You hereby agree to indemnify, defend and hold harmless the
                Company from and against any and all losses, liabilities,
                claims, damages, costs and expenses (including legal fees)
                incurred by us that arise out of, result from any breach or
                non-performance of any representation, warranty, covenant made
                or obligation to be performed by the Lab pursuant to these T&Cs.
              </li>
            </ol>
          </li>

          <li>
            DISCLAIMER OF LIABILITY
            <ol>
              <li>
                All information, content, materials, software and other services
                included on or otherwise made available to the Lab on the App is
                provided on an “as is” basis, unless otherwise specified. The
                Company make no representations or warranties of any kind,
                expressed or implied, as to the operation of the services, or
                the information, content, materials, software or other services
                included or otherwise made available through the App. The
                Company shall to the fullest extent permissible under applicable
                law disclaims all warranties, express, statutory, or implied and
                does not warrant the accuracy or completeness of the
                information, text, graphics, links or other items contained
                within the App.
              </li>
              <li>
                The Company shall not accepts any liability for any damage to
                Lab’s computer system or loss of data caused by using the App or
                claimed to be arising on account of usage of the App.
              </li>
              <li>
                The Company shall not share, sell, distribute or lease the Lab’s
                information or the information to its patient personal
                information to third parties unless Company has obtain specific
                permission or is required by law to do so.
              </li>
            </ol>
          </li>

          <li>
            MISCELLANEOUS:
            <ol>
              <li>
                Capitalized terms not defined herein shall have the meanings
                assigned to them under the MOU.
              </li>
              <li>
                Notwithstanding anything contained or said in any other
                document, if there is a conflict between the Terms mentioned
                herein and any other document, the terms contained in these T&Cs
                shall prevail for the purposes of usage of the website.
              </li>
              <li>
                You understand and agree that these T&Cs can be modified by the
                Company at its sole discretion, at any time without prior
                notice, and such modifications will be effective upon such new
                terms and/or upon implementation of the new changes on the App.
                You agree that the Company shall not be liable for any loss or
                costs suffered by You on your failure to review such modified
                Terms of Use.
              </li>
              <li>
                This T&Cs will be binding upon and inure to the benefits of the
                parties and their respective successors and assigns.
              </li>
              <li>
                If any provision of this T&Cs is held by a court of competent
                jurisdiction to be illegal, invalid or unenforceable, that
                provision shall be severed and the remaining provisions shall
                remain in full force and effect.
              </li>
              <li>
                No waiver of any breach of any provision of this T&Cs shall
                constitute a waiver of any prior, concurrent or subsequent
                breach of that provision or any other provision of this T&Cs. No
                waiver shall be effective unless made in writing and signed by
                an authorized representative of the waiving party.
              </li>
            </ol>
          </li>
        </ol> */}
      </Box>
    </>
  );
};

export default Terms;
