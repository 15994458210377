export const BASE_API_URL = process.env.REACT_APP_BASE_URL;
export const FONT_FAMILY_GENERAL = "Noto Sans Display";
export const COLOR_PRIMARY = "#0099CC";
export const COLOR_SECONDARY = "#FFF";
export const COLOR_VARIANT = "#7ABA57";
export const COLOR_GREY = "rgb(128, 128, 128)";
export const COLOR_ERROR = "#ff0033";
export const COLOR_PRIMARY_LIGHT = "#F5FBFC";
export const COLOR_LINK = "rgb(0, 153, 204)";
export const TEXT_COLOR_PRIMARY = "#000";
export const HEADER_HEIGHT = 72;
export const FOOTER_HEIGHT = "28px";
// export const API_URL = "http://13.235.113.206:8085/media/v1"; // dev
// export const API_URL = "https://preprod.api.hemehealth.app/lab/v1"; // preprod
// export const API_URL = "https://staging.api.hemehealth.app/lab/v1"; // staging
export const API_URL = "https://api.hemehealth.app/lab/v1"; // prod
// export const API_URL_UPLOAD_REPORTS = "https://staging.api.hemehealth.app/media/v1/upload"; // staging
export const API_URL_UPLOAD_REPORTS = "https://api.hemehealth.app/media/v1/upload"; // prod
// export const API_URL_UPLOAD_REPORTS = "https://preprod.api.hemehealth.app/media/v1/upload"; // preprod
// export const API_URL = process.env.REACT_APP_HEME_API;
export const API_KEY = "927d93de-b52f-4289-b090-7c9c08cea798";
export const COOKIE_EXPIRY_DAYS = 1826;
// export const APP_BASE_URL ="https://staging.share-view-portal.pages.dev/" // staging
export const APP_BASE_URL = "https://data.hemehealth.io/" // prod

export const SENTRY_DSN ="https://7ff68b502ba3556b94a0ad6721b0b8dc@o4505918795546624.ingest.us.sentry.io/4507984377020416" //prod



export const API_ENDPOINT = {
  OTP_GENERATE: `${API_URL}/auth/otp/generate`,
  OTP_VERIFY: `${API_URL}/auth/otp/verify`,
  OTP_VELIDATE: `${API_URL}/auth/otp/validate`,
  ACCESS_TOKEN: `${API_URL}/auth/otp/access`,
  LOGOUT: `${API_URL}/auth/logout`,
  REPORTS: `${API_URL}/reports`,
  REPORT: `${API_URL}/report`,
  REPORTS_SEND: `${API_URL}/add_data/report/add`,
  PRACTITIONER_ROLE_INFO: `${API_URL}/practitioner_role/info`,
  INCENTIVES: `${API_URL}/incentives`,
  PATIENT: `${API_URL}/patient`,
  DOCTOR: `${API_URL}/doctor`,
  UPLOAD: `${API_URL}/upload`,
  RECORD: `${API_URL}/patient/health-data/type/`,
  THUMBNAIL: `${API_URL}/patient/health-data/signed-thumbnail`,
  CONFIGURATION_LIST: `${API_URL}/configuration/list`,
  EDIT_CONFIGURATION: `${API_URL}/configuration`,
  CONFIG_LAB_USERS: `${API_URL}/configuration/lab_users`,
  GET_UPLOAD_RECORDS: `${API_URL}/report/`,
  POLL_RECORDS: `${API_URL}/report/uploaded_files`,
  SPLIT_PDF: `${API_URL_UPLOAD_REPORTS}/split-pdf`,
  SEND_RECORDS: `${API_URL}/patient/medical_data/type`,
  SEND_REPORT: `${API_URL}/add_data/report/add`,
  SEND_SCANS: `${API_URL}/add_data/scans/translodit/faster-send`
}

export const SIZE = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1023px',
  laptopL: '1439px',
  desktop: '2560px'
}

export const DEVICE = {
  mobileS: `(max-width: ${SIZE.mobileS})`,
  mobileM: `(max-width: ${SIZE.mobileM})`,
  mobileL: `(max-width: ${SIZE.mobileL})`,
  tablet: `(max-width: ${SIZE.tablet})`,
  laptop: `(max-width: ${SIZE.laptop})`,
  laptopL: `(max-width: ${SIZE.laptopL})`,
  desktop: `(max-width: ${SIZE.desktop})`,
  desktopL: `(max-width: ${SIZE.desktop})`
};
