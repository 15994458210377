import { useEffect } from "react";
import { useCookies } from "react-cookie";
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";
import Doctors from "../pages/Doctors";
import Home from "../pages/Home";
import LabProfile from "../pages/LabProfile";
import Login from "../pages/Login";
import Patients from "../pages/Patients";
import Reports from "../pages/Reports";
import Root from "../pages/Root";
import SingleDoctor from "../pages/SingleDoctor";
import SinglePatient from "../pages/SinglePatient";
import Terms from "../pages/Terms";
import ViewReport from "../pages/ViewReport";
import ViewReportLocal from "../pages/ViewReportLocal";
import SingleReport from "../pages/SingleReport";
import Records from "../pages/Records";
import Scans from "../pages/Scans";
import PacsLogs from "../pages/PacsLogs";
import ComplianceRoot from "../pages/Compliance";
import MainHome from "../pages/MainHome";
import Help from "../pages/Help";
import PatientReportPdf from "../pages/PatientReportPdf";
import TextEditor from "../pages/textEditor";
import PatientRoot from "../components/containers/PatientRoot/PatientRoot";
import NewScans from "../pages/NewScans";
import InfoSection from "../components/common/InfoSection/InfoSection";
import ShareReport from "../components/common/ShareReport/ShareReport";
import NewScansUi from "../pages/NewScansUi";
import AddRecords from "../components/containers/add-records";
import SendRecords from "../components/containers/send-records";

const router = createBrowserRouter([
  // {
  //   path: "/compliance",
  //   element: <ComplianceRoot />,
  // },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/terms-conditions",
    element: <Terms />,
  },
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "",
        element: <MainHome />,
      },
      {
        path: "home",
        element: <MainHome />,
      },
      {
        path: "scans",
        element: <NewScansUi />,
      },
      {
        path: "pacs-and-logs",
        element: <PacsLogs />,
      },
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "reports/:reportId",
        element: <ViewReport />,
      },
      {
        path: "reports/:reportBlobUrl",
        element: <ViewReportLocal />,
      },
      {
        path:"templates",
        element:<div style={{
          width: "80%",
          alignSelf: "center",
          margin: "0 auto",
        }}><TextEditor isReviewModal={false}/></div>
      },
      {
        path: "patients",
        element: <Patients />,
      },
      {
        path: "report/:id",
        element: <SingleReport />,
      },
      {
        path: "doctors",
        element: <Doctors />,
      },
      {
        path: "doctors/:doctorPhone",
        element: <SingleDoctor />,
      },
      {
        path: "profile",
        element: <LabProfile />,
      },
      {
        path: "help",
        element: <Help />,
      },
    ],
  },
  {
    path: "/",
    element: <PatientRoot />,
    children: [
      {
        path: "/allreports",
        element: <SinglePatient />,
      },
      {
        path: "patients/:reportId",
        element: <Records />,
      },
      {
        path: "patients/patientId/scan-info",
        element: <InfoSection />,
      },
      {
        path: "patients/patientId/share",
        element: <ShareReport />,
      },
      {
        path: "patient-records/add-records",
        element: <AddRecords />,
      },
      {
        path: "patient-records/add-records/send-records",
        element: <SendRecords />,
      },
    ]
  }
]);

const RootRouter = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["hemehealth-labs"]);

  useEffect(() => {
    if (cookies) {
      redirect("/");
    } else {
      redirect("/login");
    }
  }, []);

  return <RouterProvider router={router} />;
};

export default RootRouter;
