import {
  NavigateFunction,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
import CustomTable from "../components/common/CustomTable/CustomTable";
import SearchBox from "../components/common/SearchBox/SearchBox";
import UploadFiles from "../components/common/UploadFiles/UploadFiles";
import { HorizontalSep, VerticalSep } from "../components/styled/Gaps";
import {
  API_URL,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  DEVICE,
} from "../utilities/constants";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Button, Pagination, CircularProgress, Box, Grid } from "@mui/material";
// @ts-ignore
import { ShimmerContentBlock } from "react-shimmer-effects";
import { useCookies } from "react-cookie";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import moment from "moment";
import NoData from "../components/common/UserMessages/NoData";
import SimpleLoader from "../components/common/Loaders/SimpleLoader";
import { useSelector } from "react-redux";
import SucessIcons from "../assets/success-icon.svg";
import CloseIcons from "../assets/close-icon.svg";
import IProfileImageSmall from "../assets/profile-image-small.svg";

import { Modal, Popover } from "@mui/material";
import AtlassianDropdown from "../components/common/SearchDropdown/AtlassianDropdown";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { useAppDispatch } from "../store/store";

import { GlobalWorkerOptions } from "pdfjs-dist";
import "@react-pdf-viewer/core/lib/styles/index.css";
import ScanDeleteSure from "../components/common/Dialog/ScanDeleteSure";
import PatientReportPdf from "./PatientReportPdf";
import { ReportObj } from "../components/common/ShareReport/ShareReport.type";
import RecordItem from "../components/common/RecordItem/RecordItem";
import ChangeStatusDialog from "../components/common/Dialog/ChangeStatusDialog";
import { deleteStudy, getFileInfo } from "../api/report";
import { toast } from "react-toastify";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  getRecordsAction,
  getThumbnailURL,
} from "../store/actions/records.action";
import { resetThumbnail } from "../store/slice/thumbanils.slice";
import StatusDropdown from "../components/common/StatusDropdown/StatusDropdown";
import ConfirmationBox from "../components/common/InfoSection/ConfirmationBox";
const workerUrl = `//${window.location.host}/pdf.worker.min.js`;
GlobalWorkerOptions.workerSrc = workerUrl;

const Container = styled.div<{ bgColor?: string }>`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : COLOR_SECONDARY};
  border-radius: 12px;
  flex-grow: 1;
  padding: 30px;
  box-shadow: 0px 0px 32px 0px rgba(36, 119, 179, 0.1);
  @media ${DEVICE.mobileL} {
    padding: 20px 15px;
  }
`;

const SeeMoreText = styled.span`
  font-size: 12px;
  color: #0099cc;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SeeMoreWrapper = styled.div`
  margin: 2rem 0;
  background-color: #ebf7fb;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  width: 100px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SectionHeader = styled.div`
  color: ${COLOR_PRIMARY};
  font-size: 20px;
  font-weight: 600;
  @media ${DEVICE.mobileL} {
    font-size: 16px;
    margin-top: 10px;
    text-align: left;
  }
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  @media ${DEVICE.mobileL} {
    flex-direction: column;
  }
`;

const BackArrow = styled.img`
  width: 6px;
  @media ${DEVICE.mobileL} {
    width: 6px;
  }
`;

const GenderDobIcon = styled.img`
  width: 18px;
  margin: 0 5px 0 0;
  @media ${DEVICE.mobileL} {
    width: 24px;
  }
`;

export const ListItem = styled.div`
  font-size: 13px;
  color: #808080;
  margin-top: 5px;
`;

export const RecipientListItem = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 13px 9px;
  cursor: pointer;
  border: none;
  position: relative;
  font-size: 13px;
`;

type ListItemBoldProps = {
  breakLongWords?: boolean;
};

export const ListItemBold = styled.span<ListItemBoldProps>`
  font-size: 16px;
  margin-top: 10px;
  color: #000000;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  ${(props) =>
    props.breakLongWords &&
    `
    overflow-wrap: break-word;
    word-break: break-all;
  `}
`;
export const ThumbImage = styled.img`
  width: 80%;
  height: 100%;
  object-fit: contain;
  margin-bottom: 10px;
  border-radius: 8px;
`;

export const ThumbText = styled.div`
  position: absolute;
  bottom: 0;
  background: #30aed7;
  display: flex;
  justify-content: space-between;
  right: 0;
  color: #fff;
  left: 0;

  span {
    padding: 3px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    word-break: break-all;
  }
  .format {
    background: #0099cc;
    color: #fff;
    font-size: 13px;
    min-width: 26px;
  }
`;

export const EmptyListMessage = styled.div`
  margin: 2rem;
  color: red;
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

const SearchSection = styled.div`
  flex-basis: 50%;
`;

const TableContainer = styled.div`
  display: grid;
  grid-gap: 46px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  margin: 20px 0;
  @media ${DEVICE.laptop} {
    // display: none;
  }
`;
const TableContainerSmall = styled.div`
  display: none;
  @media ${DEVICE.laptop} {
    display: block;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: 20px 0px 0px 0px;
`;

const PaginationWrapperSmall = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0px 0px 0px;
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-gap: 46px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  margin: 20px 0;
`;

export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
`;

export const ListWrapper = styled.div`
  margin: 20px 0;
`;

export const Dropdownlist = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  min-width: 120px;
  cursor: pointer;
`;

export const CardHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    word-break: break-all;
    width: 100%;
    min-height: 18px;
  }
  .menubtn {
    position: absolute;
    z-index: 99;
    right: 0;
    top: 0;
  }
`;

const ListButtonBase = styled.button<{ active: boolean }>`
  border-radius: 25px;
  font-size: 16px;
  width: auto;
  height: 35px;
  padding: 4px 40px;
  border: none;
  background-color: ${({ active }) => (active ? "#FFFFFF" : "transparent")};
  color: ${({ active }) => (active ? "#2FAED7" : "#000")};
  cursor: pointer;
`;
export const CloaseIcon = styled.img`
  width: 40px;
  margin-right: -20px;
`;
export const SucessIcon = styled.img`
  margin-right: 20px;
`;
export const CloseButton = styled.button`
  background: none;
  border: none;
  margin-left: auto;
  cursor: pointer;
`;
export const AlertToast = styled.div`
  width: 55%;
  background-color: #d4e8ca;
  border-radius: 12px;
  height: 48px;
  align-items: center;
  display: flex;
  padding: 0 20px;
`;
const ListReviewButtonContainer = styled.div`
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background: #ebf7fa;
  width: auto;
  display: inline-flex;
  padding: 5px 8px;
  border-radius: 29px;
`;
export const MenuIconsDiv = styled.div`
  position: absolute;
  z-index: 99;
  right: 2px;
  top: 4px;
`;
const StudyViewerButton = styled.button`
  background-color: #7aba56;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  min-width: 150px;
  height: 30px;
  border: none;
  cursor: pointer;
  padding: 0 10px;
  margin-top: 5px;

  &:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
`;
export const ViewAllCard = styled.div`
  .date {
    margin-top: 4px;
    background: #f5fbfc;
    width: 40%;
    margin-bottom: 10px;
    height: 18px;
    display: block;
    border-radius: 4px;
  }
  .dec {
    margin-top: 10px;
    background: #f5fbfc;
    width: 80%;
    margin-bottom: 10px;
    height: 18px;
    display: block;
    border-radius: 4px;
  }
  .title {
    margin-top: 4px;
    background: #f5fbfc;
    width: 80%;
    margin-bottom: 10px;
    height: 18px;
    display: block;
    border-radius: 4px;
  }
`;

export const ViewButton = styled.button`
  width: 96px;
  height: 32px;
  // background: #7aba56 0% 0% no-repeat padding-box;
  // border-radius: 8px;
  color: #0099cd;
  outline: none;
  border-style: hidden;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
  background-color: transparent;
`;

export const ThumbImages = styled.div`
  position: relative;
  padding: 12px 10px;
  background: #ebf7fa;
  display: flex;
  justify-content: center;
  color: #fff;
  border-radius: 8px;
  overflow: hidden;
  height: 150px;
  align-items: center;
`;

const SelectPatientContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 40px;
  width: 375px;
  margin: 24px 40px 25px 0px;
`;

const DropdownLabel = styled.div`
  color: #000000;
  font-size: 16px;
  margin-bottom: 10px;
`;

const ViewerButton = styled.button`
  background-color: #7aba56;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  min-width: 200px;
  height: 40px;
  border: none;
  cursor: pointer;
  padding: 0 10px;

  &:disabled {
    background-color: #ddebed;
    color: #fff;
    cursor: not-allowed;
  }
`;

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledModalContent = styled.div`
  margin: 20px;
  background-color: #fff;
  outline: none;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  overflow-y: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ffffff;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1000;
  padding: 5px;
`;

const styleTTextSmall = { color: "#808080", fontSize: "12px" };
const styleTATextSmall = {
  color: "#0099CC",
  fontSize: "14px",
  cursor: "pointer",
};

const SelectedCard = styled.div`
  width: 48%;
  font-size: 14px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #0099cc;
  border-radius: 8px;
  border: 1px solid #0099cc;
  margin-bottom: 10px;
`;

export const ExistingListItem = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 13px 9px;
  cursor: pointer;
  border: none;
  position: relative;
  font-size: 13px;
`;

const ListItemPersonLogo = styled.div`
  font-size: 13px;
`;
const ListItemPersonDetail = styled.div`
  align-items: center;
  font-size: 13px;
  margin-left: 8px;
`;
const ListItemValue = styled.div`
  font-size: 16px;
`;
const Name = styled.div`
  font-size: 16px;
  color: #0099cc;
  margin-bottom: 4px;
  font-weight: bold;
`;

const tableColumnsSmall = [
  {
    key: "thumbnail",
    type: "icon",
    align: "left",
  },
  {
    type: "stack",
    columns: [
      // {
      //   type: "actionText",
      //   key: "appUserPhone",
      //   handler: (phone: string, navigate: NavigateFunction) => {
      //     navigate(`/patients/${phone}`);
      //   },
      //   align: "left",
      //   styles: styleTATextSmall,
      // },
      {
        type: "text",
        key: "fileName",
        align: "left",
        styles: styleTTextSmall,
        maxChars: 18,
      },
      {
        type: "text",
        key: "uploadDay",
        align: "left",
        styles: styleTTextSmall,
      },
      {
        type: "text",
        key: "uploadTime",
        align: "left",
        styles: styleTTextSmall,
      },
      {
        type: "text",
        key: "labUserName",
        prefix: "by",
        styles: styleTTextSmall,
      },
    ],
  },
  {
    type: "stack",
    columns: [
      {
        type: "actionButton",
        subType: "replace",
        handler: () => {},
        small: true,
      },
      {
        type: "actionButton",
        subType: "view",
        handler: () => {},
        small: true,
      },
      {
        type: "actionButton",
        subType: "whatsapp",
        handler: () => {},
        small: true,
      },
    ],
  },
];

const tableColumns = [
  {
    key: "thumbnail",
    type: "icon",
    align: "left",
  },
  {
    type: "text",
    key: "file_name",
  },
  {
    type: "text",
    key: "patientCount",
  },
  {
    type: "text",
    key: "doctorCount",
  },
  {
    type: "patient",
  },
  {
    type: "text",
    key: "uploadDate",
    align: "left",
  },
  {
    type: "text",
    key: "labUserName",
    prefix: "Sent by",
  },
  {
    type: "actionButton",
    subType: "singleReportDetails",
    handler: () => {},
  },
  {
    type: "actionButton",
    subType: "view",
    handler: () => {},
  },
];

type RecordTypeKeys = "scans" | "report" | "prescription" | "medical_letter";

type RecordsType = {
  [key in RecordTypeKeys]: string;
};
const recordsType : RecordsType= {
  scans: "Scans",
  report: "Report",
  prescription: "Prescription",
  medical_letter: "Letters and Summaries",
};

const SinglePatient = () => {
  let { patientPhone } = useParams();
  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();
  const [cookies, setCookie, removeCookie] = useCookies();
  const reportsPerPage = 5;
  const [fetchCount, setFetchCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const reloadCount = useSelector((state: any) => state.reportsReload.count);
  const [storeData, setStoreData] = useState<ReportObj | null>(null);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(1);
  const totalPages = Math.ceil(totalCount / reportsPerPage);
  const [isInnerLoading, setIsInnerLoading] = useState<boolean>(false);
  const [pageToken, setPageToken] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [patientProfileData, setPatientProfileData] = useState<any>();
  const [patientDataIsLoading, setPatientDataIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [selectedDoctor, setSelectedDoctor] = useState<any>(null);
  const [isShareShow, setShareShow] = useState<any>(false);
  const [shareSuccess, setShareSuccess] = useState<any>(false);
  const [open, setOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isShowDeleteInstance, setShowDeleteInstance] = useState(false);
  const [isShowDownload, setShowDownload] = useState(false);
  const [isDownLoading, setDownlading] = useState(false);
  const [downloadingData, setDownloadingData] = useState({});
  const [downloadingImageData, setDownloadingImageData] = useState({});
  const [scansAuthToken, setScansAuthToken] = useState("");
  const [isPDf, setIsPdf] = useState(false);
  const studyDownloadRef: any = useRef<HTMLDivElement>();
  const [existingRecipioents, setExistingRecipioents] = useState([]);
  const [isRecipientsLoading, setRecipientsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(false);
  const [isShowhangeStatus, setShowChangeStatus] = useState(false);
  const [issStatusChangeLoading, setIsStatusChangeLoading] = useState(false);
  const thumbnails = useSelector((state: any) => state?.thumbnail?.thumbnails);
  const [recordsData, setRecordsData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [isloadingThumbnail, setIsLoadingThumbnail] = useState(false);
  const [loadingSeeMore, setLoadingSeeMore] = useState(false);

  const selectedPatientId = useSelector(
    (state: any) => state?.patient?.selectedPatientId
  );

  const dispatch = useAppDispatch();

  const recordType = searchParams.get("type") as RecordTypeKeys || null;

  useEffect(() => {
    if (recordType) {
      setIsLoadingPage(true);
      fetchReportsData(recordType);
    }
  }, [fetchCount, reloadCount, currentPage]);

  useEffect(() => {
    if (pageNumber) {
      setCurrentPage(pageNumber);
    }
  }, [pageNumber]);

  const getSearchParam = (paramName: string) => {
    let paramValue = searchParams.get(paramName);
    return paramValue;
  };

  const convertDataToTableFormat = (inputData: any[]) => {
    // alert(JSON.stringify(inputData,null,2))
    const outputData = inputData.map((obj, i) => {
      return {
        key: obj["id"],
        appUserId: obj["id"] || "",
        fileName: obj["file_name"],
        patientCount: obj["patient_count"],
        doctorCount: obj["doctors_count"],
        uploadDate: moment(obj["upload_date"])
          .local()
          .format("DD-MMM-YYYY   hh:mm a"),
        uploadDay: moment(obj["upload_date"]).local().format("DD-MMM-YYYY"),
        uploadTime: moment(obj["upload_date"]).local().format("hh:mm a"),
      };
    });
    return outputData;
  };

  useEffect(() => {
    dispatch(resetThumbnail());
    const interval = setInterval(() => {
      fetchThubnailImages(true);
    }, 300000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const fetchThubnailImages = async (isResetThumbnail: boolean = false) => {
    if (recordType === "scans") return;
    const filePaths = recordsData
    .filter((item) => item?.file_path && (isResetThumbnail || !thumbnails?.[item.id]))
    .map((item) => ({
        file_path: item.file_path,
        fhir_id: item.id,
      }));
    filePaths.length &&
      dispatch(
        getThumbnailURL({
          file_objects: filePaths,
        })
      ).then(() => {
        setIsLoadingThumbnail(!isloadingThumbnail);
      });
  };

  useEffect(() => {
    if (recordsData.length && recordType !== "scans") {
      fetchThubnailImages();
    }
  }, [recordsData]);

  const fetchReportsData = async (recordType: string, isUpdate=false) => {
    try {
      const action: any = !isUpdate && pageToken
        ? await dispatch(
            getRecordsAction({
              patient_id: selectedPatientId,
              report_type: recordType,
              limit: isUpdate ? recordsData.length : 10,
              page_token: pageToken,
            })
          )
        : await dispatch(
            getRecordsAction({
              patient_id: selectedPatientId,
              report_type: recordType,
              limit: 10,
            })
          );

      if (action?.payload?.data?.status === "success") {
        const totalCount = action?.payload?.data?.total;
        const page_token = action?.payload?.data?.page_token;
        const fetchedData = action?.payload?.data?.data;
        isUpdate ?  setRecordsData([...fetchedData]) : setRecordsData([...recordsData, ...fetchedData]) ;
        setPageToken(page_token);
        setTotal(totalCount);
      } else {
        console.log("something went wrong");
        setIsLoadingPage(false);
      }
    } catch (error) {
      console.error("Error fetching data report and scan data:", error);
    } finally {
      setLoadingSeeMore(false);
      setIsLoadingPage(false);
    }
  };
  const handleSeeMore = () => {
    if (recordType) {
      setLoadingSeeMore(true);
      fetchReportsData(recordType);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: ReportObj,
    type: string,
  ) => {
    e.stopPropagation();
    if (e.target.value !== item?.status) {
      setShowChangeStatus(true);
      setStoreData({ ...item, status: e.target.value, record_type: type });
    }
  };

  const handleScanClick = (fhirId: string) => {
    navigate("/patients/patientId/scan-info", {
      state: {
        studyFhirId: fhirId,
      },
    });
  };

  const handleOnPageChange = (event: any, value: number) => {
    setSearchParams({
      ...searchParams,
      name: getSearchParam("name") || "",
      page: value.toString(),
    });
  };

  const handleNextFetch = () => {
    setIsInnerLoading(true);
    setPageNumber(pageNumber + 1);
  };

  const menuItems = [
    { label: "Share", onClick: () => onShareScans() },
    ...(storeData?.record_type === "scan"
      ? [
          { label: "Info Page", onClick: () => onDownload() },
          { label: "Delete", onClick: () => onDeleteStudy() },
        ]
      : []),
  ];


  const onShareScans = () => {
    navigate("/patients/patientId/share", {
      state: {
        data: storeData,
      },
    });
  };
  const onBackHandle = () => {
    setShareShow(false);
    selectedDoctor(null);
  };

  const fetchUrlForFile = async (item: any) => {
    setOpen(true);
    setIsLoading(true);
    setFileUrl(null);
    setFileType(item.content_type);
    const payload = {
      file_path: item?.file_path,
    };
    try {
      const response = await getFileInfo(payload);
      if (response && response?.data?.status === "success") {
        setFileUrl(response.data?.data);
      } else {
        console.log(response.status);
      }
    } catch (err) {
      console.log("error occured while fetching file info", err);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeStatus = () => {
    setShowChangeStatus(true);
  };
  const onStatusAvailable = (status: string) => {
    if (status) {
      setIsStatusChangeLoading(true);
      const token = cookies["access_token"];
      const Url = `${API_URL}/add_data/status/update`;
      const recordType = searchParams.get("type");

      let data = {
        record_type: storeData?.record_type,
        id: storeData?.id || storeData?.fhir_id,
        status: status,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .post(Url, data, config)
        .then(async(response) => {
          if (response && response.data && response.data.status === "success") {
            if (recordType) {
              await fetchReportsData(recordType, true);
            }
            setIsStatusChangeLoading(false);
            setShowChangeStatus(false);
            toast.success(
              "Status updated Successfully",
              {
                position: "bottom-right",
                autoClose: 1000,
              }
            );
          } else {
            setShowChangeStatus(false);
            setIsStatusChangeLoading(false);
            alert("Something went wrong!");
          }
        })
        .catch((error) => {
          console.log(error);
          setShareShow(false);
          setAnchorEl(null);
          setSelectedDoctor(null);
          setShowChangeStatus(false);
          setIsStatusChangeLoading(false);
        });
    } else {
      setShowChangeStatus(false);
      setIsStatusChangeLoading(false);
    }
  };

  const onDeleteStudy = () => {
    setAnchorEl(null);
    setShowDeleteInstance(true);
  };

  const onDeleteStudyById = async () => {
    if (!storeData?.id) return;
    setIsDeleteLoading(true);
    try {
      const response = await deleteStudy(storeData?.id);
      if (response && response?.data && response?.data?.status === "success") {
        fetchReportsData(storeData?.record_type);
        setIsDeleteLoading(false);
        setShowDeleteInstance(false);
        toast.success(`Successfully deleted scan ${storeData?.patient_name}`, {
          position: "bottom-right",
          autoClose: 500,
        });
      } else {
        setShowDeleteInstance(false);
        alert("Something went wrong!");
      }
    } catch (err) {
      console.log("Error occured while deleting study", err);
      setShareShow(false);
      setAnchorEl(null);
      setSelectedDoctor(null);
    }
  };

  const onDownload = async () => {
    setShowDownload(true);
    fetchDataForDownload();
  };

  function blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  async function displayProtectedImage(imageUrl: any, token: any) {
    const responses = await fetch(imageUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Image/jpeg",
      },
    });
    let dataaaa = await responses.blob();
    return dataaaa;
  }

  const fetchDataForDownload = () => {
    setDownlading(true);
    const token = cookies["access_token"];
    const Url = `${API_URL}/list_data/study/pamphlet?study_fhir_id=${storeData?.id}`;
    const scansImagesUrl = `${API_URL}/list_data/frame_urls?study_fhir_id=${storeData?.id}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(Url, config)
      .then((response) => {
        if (response && response.data && response.data.status === "success") {
          setDownloadingData(response?.data?.data);

          axios.get(scansImagesUrl, config).then(async (responseData) => {
            if (
              responseData &&
              responseData.data &&
              responseData.data.status === "success"
            ) {
              setScansAuthToken(responseData.data.data.auth_token);
              const frames = await Object.keys(
                responseData.data.data.frame_urls
              )
                ?.slice(0, 2)
                .map((frame: any) => responseData.data.data.frame_urls[frame]);

              const fetchFrameImages = async () => {
                const framesPromise = frames.map(async (frameArray) => {
                  const fetchProtectedImage = frameArray.map(
                    async (itemUrl: any) => {
                      let url = await displayProtectedImage(
                        `${itemUrl}`,
                        responseData.data.data.auth_token
                      );
                      let base64 = await blobToBase64(url);
                      return base64;
                    }
                  );
                  const fetchProtectedImageArr = await Promise.all(
                    fetchProtectedImage
                  );
                  return fetchProtectedImageArr;
                });
                return await Promise.all(framesPromise);
              };

              fetchFrameImages().then((fetchProtectedImageArr: any) => {
                setDownloadingImageData(fetchProtectedImageArr);
                setIsPdf(true);
                setTimeout(() => {
                  studyDownloadRef.current.onDownload();
                  setTimeout(() => {
                    setShowDownload(false);
                  }, 2000);
                }, 3000);
              });
            } else {
              setIsPdf(true);
              setTimeout(() => {
                studyDownloadRef.current.onDownload();
                setShowDownload(false);
                setAnchorEl(null);
              }, 3000);
            }
          });
        } else {
          setShowDownload(false);
          setDownlading(false);
          setAnchorEl(null);
          setDownloadingData({});
          setIsPdf(false);

          alert("Something went wrong!");
        }
      })
      .catch((error) => {
        console.log(error);
        setShowDownload(false);
        setIsPdf(false);

        setDownlading(false);
        setAnchorEl(null);
        setDownloadingData({});
        // setIsReportsLoading(false);
      });
  };

  const fetchExistingRecipients = () => {
    setRecipientsLoading(true);
    const token = cookies["access_token"];
    const patientId = getSearchParam("name") || "";
    const endpointUrl = `${API_URL}/share/users`;

    let data = JSON.stringify({
      patient_id: patientId,
      report_type: storeData?.record_type,
      resource_id: storeData?.id,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(endpointUrl, data, config)
      .then((response: any) => {
        if (
          response.data &&
          response.data?.status == "success" &&
          response.data?.result
        ) {
          setExistingRecipioents(response.data?.result);
          setRecipientsLoading(false);
        } else {
          alert("Something went wrong!!");
          setRecipientsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setRecipientsLoading(false);
      });
  };

  const renderExistingDoctors = () => {
    if (isRecipientsLoading) {
      return (
        <div style={{ margin: "0 auto" }}>
          {" "}
          <SimpleLoader />{" "}
        </div>
      );
    }

    return existingRecipioents.length > 0 ? (
      <>
        {existingRecipioents
          ? existingRecipioents?.map((item: any) => {
              return (
                <SelectedCard>
                  <RecipientListItem>
                    <ListItemPersonLogo>
                      <img src={IProfileImageSmall} height={32} />
                    </ListItemPersonLogo>
                    <ListItemPersonDetail>
                      <Name>{item.name}</Name>
                      <ListItemValue> {item.phone_number}</ListItemValue>
                      <ListItemValue style={{ marginBottom: "16px" }}>
                        {" "}
                        {item?.email}
                      </ListItemValue>

                      <span
                        style={{
                          color: "#808080",
                          fontSize: "14px",
                          marginTop: "20px",
                        }}
                      >
                        {item?.gender}
                        {item.dob &&
                          ", " +
                            moment().diff(
                              moment(item.dob, "YYYY-MM-DD"),
                              "years"
                            ) +
                            " Years"}
                      </span>
                    </ListItemPersonDetail>
                  </RecipientListItem>
                </SelectedCard>
              );
            })
          : null}
      </>
    ) : (
      <EmptyListMessage>No records found</EmptyListMessage>
    );
  };

  return (
    <div style={{ padding: "0px 30px" }}>
      <div
        style={{ display: "flex", flexDirection: "row", cursor: "pointer", alignItems: "center" }}
        onClick={() => {
          isShareShow ? onBackHandle() : navigate(-1);
        }}
      >
        <ArrowBackIosNewIcon sx={{ color: "#7aba56" }} style={{ fontSize: 15 }} />
        <p style={{ color: "#7ABA56", fontSize: 14, marginLeft: 5 }}>Back</p>
      </div>
      {/* <TopRow>
        <ProfileSection>
          <Container>
            <HeaderRow>
              <SectionHeader>Patient's Profile</SectionHeader>
            </HeaderRow>
            <VerticalSep size={20} />
            {
              patientDataIsLoading ? <SimpleLoader /> :
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <ProfileImage src={patientProfileImage} />
                  </div>
                  <HorizontalSep size={30} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: '100%',
                    }}
                  >
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                      <UserName>{capitalizeFirstLetter(patientProfileData?.name)}</UserName>
                      <Button
                        style={{
                          alignSelf: 'flex-end',
                        }}
                        onClick={() => {
                          if (!searchParams.has("replace")) {
                            searchParams.delete("addEditPatient");
                            searchParams.delete("patient_fhir_id");
                            searchParams.append("addEditPatient", "true");
                            searchParams.append("patient_fhir_id", getSearchParam("name") || "");
                            setSearchParams(searchParams);
                          }
                        }}
                      >
                        <img src={editIcon} style={{ marginRight: "5px" }} />
                        Edit
                      </Button>
                    </div>
                    <PhoneNumber>{patientProfileData?.phone_number}</PhoneNumber>
                    <div style={{ display: 'flex' }}>
                      {patientProfileData?.gender &&
                        <>
                          {patientProfileData?.gender == "male" ? <GenderDobIcon src={genderMaleIcon} />
                            : <GenderDobIcon src={genderFemaleIcon} />}
                          <Gender>{capitalizeFirstLetter(patientProfileData?.gender)}</Gender></>
                      }
                      {patientProfileData?.dob && <><GenderDobIcon src={calenderIcon} />
                        <Dob>{
                          moment(patientProfileData?.dob)
                            .local()
                            .format("DD-MMM-YYYY")
                        }</Dob></>}
                    </div>
                  </div>
                </div>

            }
          </Container>
        </ProfileSection>
        <HorizontalSep />
      </TopRow> */}
      <>
        {" "}
        <div>
          {recordType !== "scans" && (
            <Container>
              <HeaderRow>
                <SectionHeader>
                  {recordType && recordsType[recordType]}{" "}
                  {total > 0 ? `(${total})` : ""}
                </SectionHeader>
                {shareSuccess && (
                  <AlertToast>
                    <SucessIcon src={SucessIcons} />
                    Success! Your records are sent.
                    <CloseButton onClick={() => setShareSuccess(false)}>
                      <CloaseIcon src={CloseIcons} />
                    </CloseButton>
                  </AlertToast>
                )}
              </HeaderRow>
              {!isLoadingPage ? (
                <Grid container gap={2} flexDirection="row" mt={4}>
                  {recordsData && recordsData.length ? (
                    <>
                      {recordsData?.map((row: any, index) => (
                        <Box
                          style={{ width: 130 }}
                          key={`records-report-${index}`}
                          display="flex"
                          flexDirection="column"
                        >
                          <RecordItem
                            reportType="report"
                            fetchUrlForFile={() => fetchUrlForFile(row)}
                            setStoreData={setStoreData}
                            menuItems={menuItems}
                            row={row}
                            index={index}
                            thumbnailData={thumbnails}
                          />
                          <StatusDropdown
                            handleChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChange(e, row, "report");
                            }}
                            selectedStatus={row?.status || ""}
                          />
                        </Box>
                      ))}

                      {total > 9 &&
                        recordsData.length < total &&
                        (!loadingSeeMore ? (
                          <>
                            <ListItemWrapper key="see-more">
                              <SeeMoreWrapper onClick={handleSeeMore}>
                                <SeeMoreText>See More</SeeMoreText>
                                <KeyboardArrowRightIcon
                                  sx={{ color: "#0099cc" }}
                                />
                              </SeeMoreWrapper>
                            </ListItemWrapper>
                          </>
                        ) : (
                          <Box
                            display="flex"
                            ml={4}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <CircularProgress />{" "}
                          </Box>
                        ))}
                    </>
                  ) : (
                    <NoData message={"No Reports"} />
                  )}
                </Grid>
              ) : (
                <ShimmerContentBlock
                  title
                  text
                  cta
                  thumbnailWidth={86}
                  thumbnailHeight={86}
                />
              )}
            </Container>
          )}
          {recordType === "scans" && (
            <Container>
              <HeaderRow>
                <SectionHeader>
                  Scans {total > 0 ? `(${total})` : ""}
                </SectionHeader>
                {!shareSuccess && (
                  <SearchSection>
                    <HorizontalSep size={20} />
                  </SearchSection>
                )}
                {shareSuccess && (
                  <AlertToast>
                    <SucessIcon src={SucessIcons} />
                    Success! Your records are sent.
                    <CloseButton onClick={() => setShareSuccess(false)}>
                      <CloaseIcon src={CloseIcons} />
                    </CloseButton>
                  </AlertToast>
                )}
              </HeaderRow>
              <ListWrapper>
                {isLoadingPage ? (
                  <ShimmerContentBlock
                    title
                    text
                    cta
                    thumbnailWidth={86}
                    thumbnailHeight={86}
                  />
                ) : (
                  <Grid container gap={2} flexDirection="row" mt={4}>
                    <>
                      {recordsData && recordsData.length > 0 ? (
                        <>
                          {recordsData?.map((row: any, index) => (
                            <Box
                              key={`record-scans-${index}`}
                              style={{ width: 130 }}
                              display="flex"
                              flexDirection="column"
                            >
                              <RecordItem
                                reportType="scan"
                                fetchUrlForFile={() => handleScanClick(row?.id)}
                                setStoreData={setStoreData}
                                menuItems={menuItems}
                                row={row}
                                index={index}
                                thumbnailData={thumbnails}
                              />
                              <StatusDropdown
                                handleChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  handleChange(e, row, "scan");
                                }}
                                selectedStatus={row?.status || ""}
                              />
                            </Box>
                          ))}

                          {total > 9 &&
                            recordsData.length < total &&
                            (!loadingSeeMore ? (
                              <>
                                <ListItemWrapper key="see-more">
                                  <SeeMoreWrapper onClick={handleSeeMore}>
                                    <SeeMoreText>See More</SeeMoreText>
                                    <KeyboardArrowRightIcon
                                      sx={{ color: "#0099cc" }}
                                    />
                                  </SeeMoreWrapper>
                                </ListItemWrapper>
                              </>
                            ) : (
                              <Box
                                display="flex"
                                ml={4}
                                alignItems="center"
                                justifyContent="center"
                              >
                                <CircularProgress />{" "}
                              </Box>
                            ))}
                        </>
                      ) : (
                        <EmptyListMessage>No records found</EmptyListMessage>
                      )}
                    </>
                  </Grid>
                )}
              </ListWrapper>

              {isPDf && (
                <div style={{ marginTop: "400px" }}>
                  <PatientReportPdf
                    ref={studyDownloadRef}
                    data={downloadingData}
                    downloadingImageData={downloadingImageData}
                    setPdf={setIsPdf}
                    scansAuthToken={scansAuthToken}
                  />
                </div>
              )}
            </Container>
          )}
        </div>
        <VerticalSep size={20} />
        <VerticalSep size={20} />{" "}
      </>
      <StyledModal open={open}>
        <StyledModalContent>
          <StyledCloseButton onClick={() => setOpen(false)}>
            &times;
          </StyledCloseButton>
          {fileUrl && fileType === "application/pdf" ? (
            <Worker workerUrl={workerUrl}>
              <Viewer fileUrl={fileUrl} />
            </Worker>
          ) : (
            fileUrl && (
              <img
                height={"90%"}
                width={"90%"}
                src={fileUrl}
                style={{
                  objectFit: "contain",
                  padding: "20px",
                }}
              />
            )
          )}
          {!fileUrl && <SimpleLoader />}
        </StyledModalContent>
      </StyledModal>
      {isShowDeleteInstance ? (
        <ScanDeleteSure
          open={isShowDeleteInstance}
          onDelete={() => onDeleteStudyById()}
          isLoading={isDeleteLoading}
          testId={"test"}
          title={"Are you sure you want to delete ?"}
          discription={"This action cannot be undone once deleted."}
          closeHandler={() => {
            setShowDeleteInstance(false);
          }}
        />
      ) : (
        ""
      )}

      {isShowDownload ? (
        <ScanDeleteSure
          open={isShowDownload}
          isLoaderPopup={true}
          isLoading={isDownLoading}
          testId={"test"}
          title={""}
          discription={""}
          closeHandler={() => {
            setShowDownload(false);
          }}
        />
      ) : (
        ""
      )}

      {isShowhangeStatus ? (
        <ConfirmationBox
          title={"Are you Sure?"}
          description={
            storeData?.status === "RESTRICTED"
              ? `You are changing the ${storeData?.record_type} sharing status. The patient will lose access to the file.`
              : `You are changing the ${storeData?.record_type} sharing status. The patient will get access to the ${storeData?.record_type} and will be notified.`
          }
          closeHandler={() => {
            setShowChangeStatus(false);
            setIsStatusChangeLoading(false);
          }}
          open={isShowhangeStatus}
          onConfirm={onStatusAvailable}
          isLoading={issStatusChangeLoading}
          status={storeData?.status || ""}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default SinglePatient;
