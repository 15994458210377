import { useState, useCallback, FC, ChangeEvent, MouseEvent } from "react";
import axios from "axios";
import {
  Button,
  Box,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
} from "@mui/material";
import { useSelector } from "react-redux";
import { debounce } from "../../../utilities/helper";
import { API_URL } from "../../../utilities/constants";
import CloseIcon from "@mui/icons-material/Close";
import { useCookies } from "react-cookie";
import { RecipientInfoType, ReportObj } from "./ShareReport.type";
import { useAppDispatch } from "../../../store/store";
import {
  setShowStatus,
  unsetShowStatus,
} from "../../../store/slice/statusSlice";
import { sendReportToDoctorToAnyone } from "../../../api/report";

const errorMessages = {
  RESTRICTED_FILE: (
    <div style={{ color: "#CC8552" }}>
      You can only share restricted files to doctor and lab.
    </div>
  ),
  INVALID_EMAIL_PHONE_NO: (
    <div style={{ color: "#CC8552" }}>
      Please enter valid email or phone number.
    </div>
  ),
  ALREADY_SHARED: (
    <div style={{ color: "#0099CC" }}>
      You have already shared with this recipient.
    </div>
  ),
  AVAILABLE_NUMBER: (
    <div style={{ color: "#0099CC" }}>This profile is available.</div>
  ),
  SELECT_CONTACT: (
    <div style={{ color: "#CC8552" }}>Please select recipient’s from list.</div>
  ),
};

interface FileShareBoxProps {
  selectedContact: RecipientInfoType | null;
  handleConfirm: () => void;
}
interface FileShareBoxAnyoneProps {
  searchQuery: string;
  handleAnyone: () => void;
}
interface MobileEmailVerificationProps {
  reportId?: string;
  setRecipientInfo: (data: RecipientInfoType[]) => void;
  recipientInfo: RecipientInfoType[];
  recordType?: string;
  recordInfo: ReportObj | null;
}

const MobileEmailVerification: FC<MobileEmailVerificationProps> = ({
  reportId,
  setRecipientInfo,
  recipientInfo,
  recordType,
  recordInfo,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [contactInfo, setContactInfo] = useState<RecipientInfoType[]>([]);
  const [selectedContact, setSelectedContact] =
    useState<RecipientInfoType | null>(null);
  const [error, setError] = useState<React.ReactNode>(null);
  const [showConfirmationBox, setShowConformationBox] =
    useState<boolean>(false);
  const [isDisabledNext, setIsDisabledNext] = useState<boolean>(true);
  const [cookies] = useCookies();
  const selectedPatientId = useSelector(
    (state: any) => state?.patient?.selectedPatientId
  );
  const [isSendAnyone, setIsSendAnyone] = useState(false);
  const dispatch = useAppDispatch();
  const fetchContactRecord = async (query: string) => {
    if (query === "") {
      setIsDisabledNext(true);
      return;
    }

    try {
      const url = `/search/practitioner?phone=${query}`;
      const token = cookies["access_token"];
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const method = "POST";
      const payload = {
        search: query,
      };
      dispatch(
        setShowStatus({
          message: "Fetching contact list",
          autoHide: "no",
          severity: "info",
        })
      );
      const action = await axios({
        method,
        url: `${API_URL}${url}`,
        data: payload,
        headers: config.headers,
      });

      const response = action.data;
      if (response && response.status === "success") {
        const responseData = response?.result || [];
        setContactInfo(responseData);
        responseData?.length
          ? setIsDisabledNext(true)
          : setIsDisabledNext(false);
      } else {
        console.error("Failed to fetch contact list:", response.error);
      }
    } catch (error) {
      console.error("Failed to fetch contact list:", error);
    }
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    debouncedFetchContactRecord(e.target.value);
    setContactInfo([]);
    setError("");
    setSelectedContact(null);
    setShowConformationBox(false);
    setIsSendAnyone(false);
    setIsDisabledNext(true);
  };

  const handleSuggestionClick = (suggestion: RecipientInfoType) => {
    setSelectedContact(suggestion);
    setContactInfo([]);
    setIsDisabledNext(false);
    setError("");
  };

  const debouncedFetchContactRecord = useCallback(
    debounce(fetchContactRecord, 300),
    []
  );

  const renderCardAnyone = () => (
    <ListItem
      sx={{
        cursor: "pointer",
        borderRadius: 2,
        background: "#FFF",
        border: "2px solid #7aba57",
        display: "flex",
        justifyContent: "space-between",
        minWidth: { xs: 200, sm: 300 },
      }}
    >
      <Box display="flex" alignItems="center">
        <ListItemAvatar>
          <Avatar src="/broken-image.jpg" sx={{ width: 40, height: 40 }} />
        </ListItemAvatar>
        <ListItemText
          primary={<Typography variant="body2">{searchQuery}</Typography>}
        />
      </Box>
      <IconButton
        edge="end"
        aria-label="delete"
        sx={{ color: "red" }}
        onClick={(e) => handleRemoveContact(e)}
      >
        <CloseIcon />
      </IconButton>
    </ListItem>
  );

  const handleAnyone = async () => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isEmailValid = emailPattern.test(searchQuery);
    let contact;
    if (!isEmailValid) {
      contact = !searchQuery.startsWith("+91")
        ? `+91${searchQuery.trim()}`
        : searchQuery.trim();
    } else {
      contact = searchQuery.trim();
    }
    const payload = {
      patient_id: selectedPatientId || "",
      contact_id: contact || "",
      report_type: recordType === "scan" ? "scans" : recordType || "",
      resource_id: reportId || "",
      ...(recordType === "scan" && {
        scan_info: {
          modality: recordInfo?.modality || [],
          study_date: recordInfo?.study_date || "",
          location: recordInfo?.location || "",
          description: recordInfo?.description || "",
        },
      }),
    };
    try {
      dispatch(
        setShowStatus({
          message: "sharing report",
          severity: "info",
          autoHide: "no",
        })
      );
      const action =
        payload && (await sendReportToDoctorToAnyone({ payload: payload }));
      const response = action.data;
      if (response && response.status === "success") {
        setRecipientInfo([
          ...recipientInfo,
          {
            email: searchQuery,
          },
        ]);
        setError("");
        dispatch(
          setShowStatus({
            message:
              "This file is shared successfully.",
            autoHide: "no",
            severity: "success",
          })
        );
        setSelectedContact(null);
        setSearchQuery("");
        setShowConformationBox(false);
        setIsDisabledNext(true);
        setError("");
        setIsSendAnyone(false);
        setContactInfo([]);
      } else {
        console.error("Failed to share report:");
        if (response === "error" || action?.response?.data?.status === "error") {
          if (
            response === "Grantee already has the requested consent" ||
            action?.response?.data?.message ===
              "Grantee already has the requested consent"
          ) {
            setError(
              <div style={{ color: "#CC8552" }}>
                This recipient already has access to this file.
              </div>
            );
            dispatch(
              setShowStatus({
                message: "This recipient already has access to this file.",
                autoHide: "no",
                severity: "error",
              })
            );
            return;
          }
          dispatch(
            setShowStatus({
              message: response || action?.response?.data?.message  ,
              autoHide: "no",
              severity: "error",
            })
          );
          setError(<div style={{ color: "#CC8552" }}>{response || action?.response?.data?.message}</div>);
        } else {
          dispatch(
            setShowStatus({
              message: "Error occurred while sharing the record.",
              autoHide: "no",
              severity: "error",
            })
          );
        }
      }
    } catch (error) {
      console.error("Failed to share report:", error);
      if (axios.isAxiosError(error)) {
        setShowConformationBox(false);
        if (
          error?.response?.data?.message &&
          error.response.data.message ===
            "Grantee already has the requested consent"
        ) {
          setError(
            <div style={{ color: "#CC8552" }}>
              This recipient already has access to this file.
            </div>
          );
          dispatch(
            setShowStatus({
              message: "This recipient already has access to this file.",
              autoHide: "no",
              severity: "error",
            })
          );
          return;
        }
        error?.response?.data?.message &&
          dispatch(
            setShowStatus({
              message: error?.response?.data?.message,
              autoHide: "no",
              severity: "error",
            })
          );
      } else {
        dispatch(
          setShowStatus({
            message: "Error occurred while sharing the record.",
            autoHide: "no",
            severity: "error",
          })
        );
      }
    } finally {
      setShowConformationBox(false);
    }
  };

  const handleNext = () => {
    if (!selectedContact && contactInfo.length) {
      setError(errorMessages.SELECT_CONTACT);
      setIsDisabledNext(true);
      return;
    }
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const mobilePattern = /^(\+91\s?)?[0-9]{10}$/;
    const isEmailValid = emailPattern.test(searchQuery);
    const isMobileValid = mobilePattern.test(searchQuery);
    if (!selectedContact && !isEmailValid && !isMobileValid) {
      setError(errorMessages.INVALID_EMAIL_PHONE_NO);
      return;
    }
    if (!selectedContact) {
      if (recordInfo?.status === "RESTRICTED") {
        setError(errorMessages.RESTRICTED_FILE);
        return;
      }
      setIsSendAnyone(true);
      setIsDisabledNext(true);
      setShowConformationBox(true);
      return;
    }
    if (
      recipientInfo.some(
        (item) => item.grantee_id === (selectedContact && selectedContact.id)
      )
    ) {
      setError(errorMessages.ALREADY_SHARED);
      setIsDisabledNext(true);
      return;
    }
    setShowConformationBox(true);
    setError(errorMessages.AVAILABLE_NUMBER);
    setIsDisabledNext(true);
  };

  const handleConfirm = async () => {
    const patientId = selectedPatientId;
    const token = cookies["access_token"];
    const payload = {
      patient_id: patientId,
      grantee_id: selectedContact?.id,
      grantee_type: "Practitioner",
      report_type: recordType === "scan" ? "scans" : recordType,
      resource_id: reportId,
      ...(recordType === "scan" && {
        scan_info: {
          modality: recordInfo?.modality,
          study_date: recordInfo?.study_date,
          location: recordInfo?.location,
          description: recordInfo?.description,
        },
      }),
    };
    try {
      const Url = `${API_URL}/share`;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(Url, payload, config);
      if (response && response.data.status === "success") {
        setRecipientInfo([
          ...recipientInfo,
          {
            grantee_id: selectedContact?.id,
            email: selectedContact?.email,
            name: selectedContact?.name,
            phone_number: selectedContact?.phone,
          },
        ]);
        setError("");
        dispatch(
          setShowStatus({
            message:
              "This file is shared successfully.",
            autoHide: "no",
            severity: "success",
          })
        );
        setSelectedContact(null);
        setSearchQuery("");
        setShowConformationBox(false);
        setIsDisabledNext(true);
        setError("");
        setIsSendAnyone(false);
        setContactInfo([]);
      } else {
        setShowConformationBox(false);
        console.error("Failed to share report:");
        dispatch(
          setShowStatus({
            message: "Error occurred while sharing the record.",
            autoHide: "no",
            severity: "error",
          })
        );
      }
    } catch (error) {
      console.error("Failed to share report:", error);
      if (axios.isAxiosError(error)) {
        setShowConformationBox(false);
        if (
          error?.response?.data?.message &&
          error.response.data.message ===
            "Grantee already has the requested consent"
        ) {
          setError(
            <div style={{ color: "#CC8552" }}>
              This recipient already has access to this file.
            </div>
          );
          dispatch(
            setShowStatus({
              message: "This recipient already has access to this file.",
              autoHide: "no",
              severity: "error",
            })
          );
          return;
        }
        error?.response?.data?.message &&
          dispatch(
            setShowStatus({
              message: error?.response?.data?.message,
              autoHide: "no",
              severity: "error",
            })
          );
      } else {
        dispatch(
          setShowStatus({
            message: "Error occurred while sharing the record.",
            autoHide: "no",
            severity: "error",
          })
        );
      }
    } finally {
      setShowConformationBox(false);
    }
  };

  const handleRemoveContact = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSelectedContact(null);
    setSearchQuery("");
    setShowConformationBox(false);
    setIsDisabledNext(true);
    setError("");
    setIsSendAnyone(false);
    setContactInfo([]);
    dispatch(unsetShowStatus());
  };

  const renderCard = (contact: RecipientInfoType) => (
    <ListItem
      key={contact.id}
      sx={{
        cursor: "pointer",
        borderRadius: 2,
        background: "#FFF",
        border: "2px solid #7aba57",
        display: "flex",
        justifyContent: "space-between",
        minWidth: { xs: 200, sm: 300 },
      }}
    >
      <Box display="flex" alignItems="center">
        <ListItemAvatar>
          <Avatar src="/broken-image.jpg" sx={{ width: 40, height: 40 }} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography component="span" variant="body2" color="#0099CC">
              {contact?.name}
            </Typography>
          }
          secondary={
            <>
              <Typography component="span" variant="body2" color="textPrimary">
                {contact?.phone}
              </Typography>
              <div>
                <Typography variant="body2">{contact?.email}</Typography>
              </div>
            </>
          }
        />
      </Box>
      <IconButton
        edge="end"
        aria-label="delete"
        sx={{ color: "red" }}
        onClick={handleRemoveContact}
      >
        <CloseIcon />
      </IconButton>
    </ListItem>
  );

  return (
    <>
      <Box sx={{ gap: 1 }} mb={2}>
        <Box mb={1}>Enter new recipient’s E-mail or Mobile Number.</Box>
        <Box display="flex" gap={1}>
          <Box display="flex" flexDirection="column">
            {!selectedContact && !isSendAnyone && (
              <TextField
                variant="outlined"
                placeholder="Search..."
                sx={{
                  background: "#FFF",
                  minWidth: { xs: 200, sm: 300, md: 350 },
                  height: "40px",
                  "& .MuiInputBase-root": {
                    height: "100%",
                  },
                  marginRight: 1,
                }}
                onChange={handleSearchChange}
                value={searchQuery}
              />
            )}

            {isSendAnyone && renderCardAnyone()}
            {selectedContact && renderCard(selectedContact)}
            {contactInfo.length > 0 && (
              <List
                sx={{
                  maxHeight: 200,
                  overflow: "auto",
                  background: "#FFF",
                  borderRadius: 2,
                  mr: 1,
                }}
              >
                {contactInfo.map((contact, index) => (
                  <ListItem
                    key={index}
                    onClick={() => handleSuggestionClick(contact)}
                    sx={{
                      cursor: "pointer",
                      borderRadius: 2,
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                      },
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src="/broken-image.jpg"
                        sx={{ width: 40, height: 40 }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          component="span"
                          variant="body2"
                          color="#0099CC"
                        >
                          {contact?.name}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                          >
                            {contact?.phone}
                          </Typography>
                          <Typography variant="body2">
                            {contact?.email}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
          <Button
            variant="contained"
            sx={{
              borderRadius: 2,
              height: "40px",
              color: "#FFF",
              width: 80,
            }}
            onClick={handleNext}
            disabled={isDisabledNext}
          >
            Next
          </Button>
        </Box>
        {error && <Box mt={1}>{error}</Box>}
      </Box>
      {showConfirmationBox && selectedContact && (
        <FileShareBox
          selectedContact={selectedContact}
          handleConfirm={handleConfirm}
        />
      )}
      {showConfirmationBox && isSendAnyone && searchQuery && (
        <FileShareAnyoneBox
          searchQuery={searchQuery}
          handleAnyone={handleAnyone}
        />
      )}
    </>
  );
};

const FileShareBox: FC<FileShareBoxProps> = ({
  selectedContact,
  handleConfirm,
}) => {
  return (
    <Box
      p={2}
      mt={2}
      mb={2}
      sx={{ background: "#D7EFF7", maxWidth: 250, borderRadius: 2 }}
    >
      <Typography mb={1}>
        {selectedContact?.name ||
          selectedContact?.email ||
          selectedContact?.phone}
      </Typography>
      <Typography sx={{ fontSize: "0.8rem", color: "#808080" }} mb={2}>
        Are you sure about sharing?
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ color: "#FFF" }}
        onClick={handleConfirm}
      >
        Confirm
      </Button>
    </Box>
  );
};

const FileShareAnyoneBox: FC<FileShareBoxAnyoneProps> = ({
  searchQuery,
  handleAnyone,
}) => {
  return (
    <Box
      p={2}
      mt={2}
      mb={2}
      sx={{ background: "#D7EFF7", maxWidth: 250, borderRadius: 2 }}
    >
      <Typography
        mb={1}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: 240,
        }}
        title={searchQuery}
      >
        {searchQuery}
      </Typography>
      <Typography sx={{ fontSize: "0.8rem", color: "#808080" }} mb={2}>
        Are you sure about sharing?
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ color: "#FFF" }}
        onClick={handleAnyone}
      >
        Confirm
      </Button>
    </Box>
  );
};

export default MobileEmailVerification;
